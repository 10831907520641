import { ajaxSync } from './ajax';
import sleep from './sleep';
import _ from 'lodash';

// 接口请求优化
// 同一个接口短时间内(5秒) 多次请求 会返回第一次请求得到的结果

let timeLimit = 5 * 1000; //5秒
let save = [];

//从save变量里面查找请求参数相同的结果
function searchSave(params) {
  const matchItem = save.find(item => _.isEqual(item.params, params));
  if (matchItem) {
    const now = Date.now();
    if (now - matchItem.saveTime < timeLimit) {
      return matchItem;
    }
  }
  return null;
}

//把请求得到的结果保存到save变量
function setSave(params, data) {
  const matchItem = searchSave(params);
  if (matchItem) {
    matchItem.data = data;
    matchItem.saveTime = Date.now();
  } else {
    save.push({
      params, data,
      saveTime: Date.now()
    });
  }
}

//清除save变量里面超时(5秒)的数据
function clearSave() {
  const now = Date.now();
  save = save.filter(item => {
    const { saveTime } = item;
    return now - saveTime < timeLimit;
  });
}

function copyData(data) {
  const { err, res } = data;
  const result = {};
  result.err = err;
  result.res = JSON.parse(JSON.stringify(res));
  return result;
}


let loading = false;

async function request(url, params, setOptions, method) {
  const saveParams = { url, params, setOptions, method };
  clearSave();
  const searchResult = searchSave(saveParams);
  if (searchResult) {
    return copyData(searchResult.data);
  } else {
    if (loading) {
      while (true) {
        await sleep(100);
        if (!loading) {
          break;
        }
      }
    }
    loading = true;
    const data = await ajaxSync[method](url, params, setOptions);
    loading = false;
    setSave(saveParams, data);
    return data;
  }
}

async function get(url, params, setOptions) {
  const data = await request(url, params, setOptions, 'get');
  return data;
}

async function post(url, params, setOptions) {
  const data = await request(url, params, setOptions, 'post');
  return data;
}

function setHeaders(headers) {
  ajaxSync.setHeaders(headers);
}

export default { get, post, setHeaders };