import './polyfill';
import dva from 'dva';
// import createHistory from 'history/createHashHistory';
import createLoading from 'dva-loading';
import 'moment/locale/zh-cn';
import './rollbar';
import './index.less';
//
const createHistory = require("history").createHashHistory;
//
const ua = window.navigator.userAgent.toLocaleLowerCase();
const isIE11 = ua.indexOf('trident') >= 0 && ua.indexOf("rv:11.0") >= 0;
if (isIE11) {
  document.body.className += ' ie11';
}
//
export default (setOptions = {}) => {
  const defaultOptions = {};
  const options = {...defaultOptions, ...setOptions};
  const {beforeStart} = options;
  //
  const app = dva({history: createHistory({hashType: 'slash'})});
  app.use(createLoading());
  //
  const startApp = () => {
    app.model(require('framework/models/global').default);
    app.router(require('./router').default);
    app.start('#root');
  };
  //
  if (beforeStart) {
    beforeStart(startApp);
  } else {
    startApp();
  }
  return app;
}
// export default app._store; // eslint-disable-line
