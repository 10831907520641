import {getConfig} from 'framework/siteConfig';

const apiUrl = getConfig('apiUrl') || {};
export default {
  namespace: 'apiUrl',
  state: {
    ...apiUrl,
  },
  effects: {},
  reducers: {},
  subscriptions: {},
};
