/*
 * @Descripttion: 
 * @version: 
 * @Author: 
 * @Date: 2019-11-26 15:21:33
 * @LastEditors: duyaoyao
 * @LastEditTime: 2022-11-04 11:05:50
 */
import config from 'src/framework/common/config';

let defaultConfig = 'imageView2/2/w/1200';

export function setDefaultImageConfig(newDefaultConfig) {
  defaultConfig = newDefaultConfig;
}

export default function (imgId, qiniuConfig) {
  if (imgId.indexOf('http') === 0 || imgId.indexOf('/') >= 0) {
    return imgId;
  }
  return imgId ? `${config.fileDomain}${imgId}` : '';
};
