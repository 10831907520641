import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Input, AutoComplete as _AutoComplete } from 'element-react';
import trim from 'framework/utils/trim';

export const AutoComplete = _AutoComplete;

export default forwardRef((props, ref) => {
  const { onChange, notTrim, onBlur, onFocus } = props;
  const handleTrim = useCallback(e => {
    onChange && !notTrim && onChange(trim(e.target.value));
    onBlur && onBlur(e);
  }, [onChange, notTrim, onBlur]);

  const handleFocus = useCallback(e => {
    onFocus && onFocus(e);
  }, [onFocus]);

  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    handleTrim,
    handleFocus,
    ...inputRef.current
  }));
  return (
    <Input
      ref={inputRef}
      {...props}
      onBlur={handleTrim}
      onFocus={handleFocus}
    />
  );
});

