const getFormItem = (dom) => {
  let formItem = null;
  while (true) {
    const isFormItem = dom.className.split(' ').includes('el-form-item');
    const isBody = dom.tagName.toLocaleLowerCase() === 'body';
    if (isFormItem) {
      formItem = dom;
    }
    if (isFormItem || isBody) {
      break;
    } else {
      dom = dom.parentElement;
    }
  }
  return formItem;
};
const findReact = function (dom) {
  for (const key in dom) {
    if (key.startsWith("__reactEventHandlers$")) {
      const comp = dom[key];
      return comp;
    }
  }
  return null;
};
const triggerChange = (dom) => {
  const formItem = getFormItem(dom);
  if (formItem) {
    const foemItemReactComp = findReact(formItem);
    if (foemItemReactComp && foemItemReactComp.onChange) {
      foemItemReactComp.onChange();
    }
  }
};

export default triggerChange;