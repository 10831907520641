import React from 'react';
import { connect } from 'dva';
import styles from './index.less';
import BaseSearch from 'components/base/BaseSearch';
import List from './List';
import { ajaxSync } from 'framework/utils/ajax';
//
@connect()
export default class LinkBox extends React.Component {
  state = { isLoading: false, options: [] };
  loadOptions = async (query = '') => {
    const { remoteUrl, params, queryKey, onLoadSuccess } = this.props;
    this.setState({ isLoading: true });
    const setParams = { ...params };
    if (query) {
      setParams[queryKey] = query;
    }
    const { err, res } = await ajaxSync.get(remoteUrl, setParams);
    if (onLoadSuccess) {
      onLoadSuccess(res);
    }
    this.setState({ isLoading: false });
    this.setState({ options: res.results.list });
  };
  handleChange = (value) => {
    const { onChange, valueKey } = this.props;
    const option = this.state.options.find(opt => opt[valueKey] === value);
    onChange && onChange(value, option);
  };

  componentDidMount() {
    this.loadOptions();
  }

  componentDidUpdate(prevProps) {
    const { params } = this.props;
    if (JSON.stringify(prevProps.params) !== JSON.stringify(params)) {
      this.loadOptions();
    }
  }

  render() {
    const { labelKey, valueKey, height, value, style } = this.props;
    const listData = this.state.options.map(opt => ({ id: opt[valueKey], name: opt[labelKey], child: opt.child }));
    return (
      <div style={{ height, ...style }} className={styles.searchBox}>
        <List activeValue={value} onChange={this.handleChange} className={styles.listWrap} data={listData}/>
        <div className={styles.baseSearchWrap}>
          <BaseSearch onSearch={this.loadOptions} className={styles.searchInput}/>
        </div>
      </div>
    );
  }
}
LinkBox.defaultProps = {
  queryKey: 'inputValue',
  labelKey: 'name',
  valueKey: 'id',
  height: 300,
  params: {},
};
