import axios from 'axios';
import sleep from './sleep';

//是否开启并行请求,默认开启 关闭后,在上一个请求结束前 不会开启下一个请求
let parallel = true;
export const setParallel = (newParallel) => {
  parallel = newParallel;
};

//上一个请求是否正在进行中
let loading = false;

//防止执行resolve/reject报错
function funcCatch(func, args) {
  try {
    func(...args);
  } catch (err) {
    console.error(err);
  }
}

function request(args, method) {
  if (parallel) {
    return axios[method](...args);
  }
  return new Promise(async (resolve, reject) => {
    while (loading) {
      await sleep(10);
    }
    //----------------------------------------
    loading = true;
    let complete = false;
    axios[method](...args).then((...argsThen) => {
      funcCatch(resolve, argsThen);
      loading = false;
      complete = true;
    }).catch((...argsCatch) => {
      funcCatch(reject, argsCatch);
      loading = false;
      complete = true;
    });
    //-----请求如果5秒后还没完成,就取消下一个请求的拦截;
    setTimeout(() => {
      if (!complete) {
        complete = true;
        loading = false;
      }
    }, 5000);
    //----------------------------------------
  })
}

export default {
  get(...args) {
    return request(args, 'get');
  },
  post(...args) {
    return request(args, 'post');
  },
  put(...args) {
    return request(args, 'put');
  },
  delete(...args) {
    return request(args, 'delete');
  }
}