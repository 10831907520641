import React, { createRef } from 'react';
import {DateRangePicker} from 'element-react';
import moment from 'moment';
import './index.less';
//
export default class extends React.Component {
  dateRef = createRef();

  state = {
    year: ''
  }

  static getDerivedStateFromProps(props, state) {
    if(props.year && props.year !== state.year) {
      return {
        year: props.year
      }
    }

    return null;
  }

  componentDidUpdate(preProps, preState) {
    const { year, value } = this.props;
    if(year && String(preProps.year) !== String(year)) {
      this.handleChange(value)
    }
  }

  getValueType = () => {
    const {value, valueType = 'array'} = this.props;
    if (Array.isArray(value)) {
      return Array;
    } else if (typeof value === 'string') {
      return String;
    } else if (valueType === 'array') {
      return Array;
    } else if (valueType === 'string') {
      return String;
    }
  };

  hasValue = () => {
    const {value} = this.props;
    if (!value) {
      return false;
    }
    if (this.getValueType() === Array && value.length === 0) {
      return false;
    }
    return true;
  };

  handleChange = value => {
    const {onChange, splitStr} = this.props;
    const format = this.getValueFormat();
    const val = value ? value : [];
    const [start, end] = val;
    const newValue = start && end ? [this.getDefaultYearValue(start).format(format), this.getDefaultYearValue(end).format(format)] : [];
    if (onChange) {
      if (this.getValueType() === Array) {
        splitStr ? onChange(newValue.join(splitStr)) : onChange(newValue);
      } else {
        onChange(newValue.join(' ~ '));
      }
    }
  };

  getValueFormat() {
    const { selectionMode, isShowTime, valueFormat } = this.props;
    if (valueFormat) {
      return valueFormat
    }
    if (selectionMode === 'month') {
      return 'YYYY-MM';
    }
    if (isShowTime || selectionMode === 'time') {
      return 'YYYY-MM-DD HH:mm:ss';
    }
    return 'YYYY-MM-DD';
  };

  getPickerFormat(props) {
    if (props.valueFormat) {
      return props.valueFormat
    }
    if (props.selectionMode === 'month') {
      return 'yyyy-MM';
    }
    if (props.selectionMode === 'time' || props.isShowTime) {
      return 'yyyy-MM-dd HH:mm:ss';
    }
    return 'yyyy-MM-dd';
  };

  // 是否默认年
  getDefaultYearValue = value => {
    const { year } = this.props;
    return year ? moment(value).year(year) : moment(value);
  }

  getValue = () => {
    let {value = [], year} = this.props;
    if (this.hasValue() && this.getValueType() === String) {
      value = value.split(' ~ ');
    }
    if (!this.hasValue()) {
      value = [];
    }
    const [start, end] = value;

    if(start && end) {
      return [new Date(this.getDefaultYearValue(start).valueOf()), new Date(this.getDefaultYearValue(end).valueOf())];
    } else if(year) {
      return [new Date(this.getDefaultYearValue('2021-1-1').valueOf()), new Date(this.getDefaultYearValue('2021-12-31').valueOf())];
    } else {
      return []
    }
  };

  getDisabledDate = date=> {
    const { year } = this.props;
    if(year) {
      return new Date(this.getDefaultYearValue('2021-1-1')).getTime() > date.getTime() || new Date(this.getDefaultYearValue('2021-12-31')).getTime() < date.getTime()
    }

    return false;
    
  }

  togglePickerVisible = () => this.dateRef.current.togglePickerVisible()

  render() {
    const defaultProps = {};
    const finalProps = {...defaultProps, ...this.props};
    finalProps.format = this.getPickerFormat(finalProps);
    const value = this.getValue();
    return (
      <div className="cjm-date-range-picker">
        <DateRangePicker 
          ref={this.dateRef} 
          {...finalProps} 
          rangeSeparator=' ~ ' 
          value={value} 
          defaultPickerValue={value} 
          disabledDate={this.getDisabledDate} 
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
