import React from "react";
import { Modal } from "components";
import ExportForm from "./ExportForm";
import { prefix } from "framework/common/config";
import { Message } from "framework/components";
//
export default class extends React.Component {
  state = {
    formData: {
      range: "1",
      exportMetadata: []
    }
  };

  getColumns() {
    const { columns } = this.props;
    return columns.filter(item => !!item.prop);
  }

  componentWillMount() {
    const { formData } = this.state;
    const exportMetadata = this.getColumns().map(item => item.prop);
    formData.exportMetadata = exportMetadata;
    this.setState({ formData });
  }

  componentWillReceiveProps(nextProps) {
    const { formData } = this.state;
    const exportMetadata = nextProps.columns
      .filter(item => !!item.prop)
      .map(item => item.prop);
    if (
      exportMetadata.toString() !=
      formData.exportMetadata.toString()
    ) {
      formData.exportMetadata = exportMetadata;
      this.setState({ formData });
    }
  }

  show = () => {
    this.refs.modal.show();
  };
  getLabelByProp = prop => {
    const { columns } = this.props;
    const col = columns.find(item => item.prop === prop);
    return col ? col.label : "";
  };
  getFormatExportMetadata = () => {
    const { formData } = this.state;
    const result = {};
    formData.exportMetadata.forEach(item => {
      result[item] = this.getLabelByProp(item);
    });
    return JSON.stringify(result);
  };
  hideDiv = null;
  exportPost = (apiUrl, params) => {
    if (this.hideDiv) {
      document.body.removeChild(this.hideDiv);
      this.hideDiv = null;
    }
    const hideDiv = document.createElement("div");
    this.hideDiv = hideDiv;
    const form = document.createElement("form");
    hideDiv.style.display = "none";
    form.setAttribute("target", "_blank");
    form.setAttribute("action", apiUrl);
    form.setAttribute("method", "post");
    document.body.appendChild(hideDiv);
    hideDiv.appendChild(form);
    Object.keys(params).forEach(key => {
      const input = document.createElement("input");
      input.setAttribute("name", key);
      input.setAttribute("value", params[key].toString());
      form.appendChild(input);
    });
    form.submit();
  };
  exportGet = (apiUrl, params) => {
    if (this.hideDiv) {
      document.body.removeChild(this.hideDiv);
      this.hideDiv = null;
    }
    const hideDiv = document.createElement("div");
    this.hideDiv = hideDiv;
    const form = document.createElement("form");
    hideDiv.style.display = "none";
    form.setAttribute("target", "_blank");
    form.setAttribute("action", apiUrl);
    form.setAttribute("method", "get");
    document.body.appendChild(hideDiv);
    hideDiv.appendChild(form);
    Object.keys(params).forEach(key => {
      const input = document.createElement("input");
      input.setAttribute("name", key);
      input.setAttribute("value", params[key].toString());
      form.appendChild(input);
    });
    form.submit();
  };
  getIds = () => {
    const { range } = this.state.formData;
    if (range == 1) {
      //导出全部
      return "";
    }
    const { getSelectedRows, rowKey } = this.props;
    const selectRows = getSelectedRows();
    return selectRows.map(rowKey).join(",");
  };
  getDataList = () => {
    const { range } = this.state.formData;
    if (range == 1) {
      //导出全部
      return "";
    }
    const { getSelectedRows } = this.props;
    const selectRows = getSelectedRows();
    return JSON.stringify(selectRows);
  };
  submit = async callback => {
    const { range } = this.state.formData;
    const { apiUrl, getSearchInfo, ajaxParams = {}, paramsFormat } = this.props;
    const { search, advancedSearch, ...otherSearchInfo } = getSearchInfo();
    let params = { ...ajaxParams, ...otherSearchInfo };
    params.exportMetadata = this.getFormatExportMetadata();
    params.search = search;
    params.advancedSearch = JSON.stringify(advancedSearch);
    params.ids = this.getIds();
    params.dataList = this.getDataList();
    if (typeof params.flag === 'undefined') {
      if (params.advancedSearch === '' || params.advancedSearch === '{}') {
        params.flag = 1; //非高级搜索
      } else {
        params.flag = 0; //高级搜索
      }
    }
    // console.log(params);
    if (range == 1 || params.ids) {
      if (typeof paramsFormat === "function") {
        params = await paramsFormat(params);
      }
      this.exportPost(`${prefix}${apiUrl}`, params);
      this.refs.modal.hide();
    } else {
      Message.error("请选择需要导出的数据");
    }
    callback();
  };
  setFormData = fieldName => {
    return fieldValue => {
      const { formData } = this.state;
      formData[fieldName] = fieldValue;
      this.setState({ formData });
    };
  };

  render() {
    const { formData } = this.state;
    const { exportRangeEnum } = this.props;
    return (
      <Modal title="导出" ref="modal" okHandler={this.submit}>
        <ExportForm
          ref="form"
          exportRangeEnum={exportRangeEnum}
          formData={formData}
          setFormData={this.setFormData}
          columns={this.getColumns()}
        />
      </Modal>
    );
  }
}
