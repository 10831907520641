import { ajaxSync } from '../utils/ajax';
import { Message } from 'framework/components';
import * as storage from 'framework/utils/storage';
import api from 'framework/common/api'

const { error } = Message;
// 获取直接上传文件url
export const getUploadUrl = async (data) => {
  const { err, res } = await ajaxSync.get(api.newFile.GET_UPLOAD_URL, data);
  if (err) {
    error(err.message);
    return null;
  } else {
    return res.results;
  }
};

// export const sendContent = async (from) => {
//   const { err, res } = await ajaxSync.post('/sms/send/phone/message', from);
//   if (err) {
//     error(err.message);
//     return null;
//   } else {
//     return res;
//   }
// };
