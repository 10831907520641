import React from 'react';
import {Icon} from 'components';
import {connect} from 'dva';
import styles from './index.less';
import {Scrollbars} from 'react-custom-scrollbars';
import classNames from 'classnames';
//
@connect()
export default class LinkBox extends React.Component {
  handleClick = (item) => {
    const {onChange, activeValue} = this.props;
    const isActive = item.id === activeValue;
    isActive || onChange(item.id);
  };

  render() {
    const {className, data, activeValue} = this.props;
    return (
      <div className={className}>
        <Scrollbars className={styles.scrollbars}>
          <div className={styles.listGroup}>
            {data.map((item, index) => {
              const isActive = item.id === activeValue;
              return (
                <div className={classNames(styles.option, isActive && styles.current)} onClick={() => this.handleClick(item)} key={index}>
                  <div className={styles.optionName}>{item.name}</div>
                  {!item.child && <Icon className={styles.rightArrow} type="right"/>}
                </div>
              );
            })}
          </div>
        </Scrollbars>
      </div>
    );
  }
}
