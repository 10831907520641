import React from "react";
import { Modal, Message } from "components";
import ImportForm from "./ImportForm";
import api from "src/sites/base/config/api";
import { ajaxSync } from "framework/utils/ajax";
import config from "framework/common/config";
import styles from "./ImportForm.less";
import _ from "lodash";

const { success } = Message;
const { fileDomain } = config;
export default class extends React.Component {
  state = {
    formData: {
      excelTemplate: "",
      errMsg: "",
      errArr: [],
      successCount: "",
      uploadResults: "",
    },
    endLoad: false,
  };

  async componentWillMount() {
    // const {uniqueCode} = this.props;
    // // const { res } = await ajaxSync.get(api.fileManage.excelDownLoad, { uniqueCode: uniqueCode });
    // // if (res) {
    // //   console.log(res)
    // //   const { formData } = this.state;
    // //   const { results } = res;
    // //   formData.excelTemplate = results;
    // //   this.setState({ formData });
    // // }
    // const {formData} = this.state;
    // formData.excelTemplate = `${prefix}${api.fileManage.excelDownLoad}?uniqueCode=` + uniqueCode;
    // this.setState({formData});
  }

  show = () => {
    const { formData } = this.state;
    formData.errMsg = "";
    this.setState({ formData });
    this.refs.modal.show();
  };

  submit = async (callback) => {
    const { ajaxParams = {}, ajaxParamsFormat } = this.props;
    const { formData } = this.state;
    const data = this.refs.importForm.getExcelParams();
    if (data && data.uniqueCode != null) {
      let formatData = Object.assign({}, data, ajaxParams);
      ajaxParamsFormat && (formatData = ajaxParamsFormat(formatData));
      const { apiUrl, refresh } = this.props;
      const { res } = await ajaxSync.post(apiUrl, formatData);
      this.refs.importForm.clearExcel();
      if (res.state == 200) {
        if (
          res.results &&
          (_.get(res, "results.errorMsg.length") > 0 ||
            _.get(res, "results.errCount") > 0)
        ) {
          formData.successCount = res.results.successCout || "";
          formData.errCount = res.results.errCount || "";
          formData.errFileCode = res.results.uniqueCode || "";
          formData.errFileName = res.results.fileName || "";
          formData.errArr =
            res.results.errorMsg instanceof Array ? res.results.errorMsg : [];
          this.setState({
            endLoad: true,
          });
        } else {
          this.refs.modal.hide();
          refresh(res && res.results);
          success("全部导入成功！");
        }
      } else {
        formData.errMsg = res.msg;
      }
    }
    this.setState({ formData });
    callback();
  };
  setFormData = (fieldName) => {
    return (fieldValue) => {
      const { formData } = this.state;
      formData[fieldName] = fieldValue;
      this.setState({ formData });
    };
  };

  clear = () => {
    const { refresh } = this.props;
    const { formData } = this.state;
    formData.excelTemplate = "";
    formData.errMsg = "";
    formData.errArr = [];
    formData.successCount = "";
    refresh && refresh();
    this.setState({
      formData,
    });
  };

  //保存文件上传后的结果
  saveUploadResults = (value) => {
    this.setState({ uploadResults: value });
  };

  //仅用于上传文件
  upload = (callback) => {
    const { saveResults } = this.props;
    const { uploadResults } = this.state;
    saveResults(uploadResults);
    this.refs.modal.hide();
    callback();
  };

  render() {
    //onlyUpload 代表仅仅上传文件 不做其他操作（例：数字乡村三资管理）
    const {
      uniqueCode,
      onlyUpload = false,
      templateUrl,
      showErrFile,
      isNewUpload,
      isTxt,
    } = this.props;
    const { formData, endLoad } = this.state;

    let errFileUrl = "";
    if (formData.errFileCode) {
      errFileUrl = `${fileDomain}/${formData.errFileCode}?attname=${formData.errFileName}`;
    }

    return (
      <Modal
        title="导入"
        ref="modal"
        okHandler={onlyUpload ? this.upload : this.submit}
        cancelHandler={this.clear}
      >
        <ImportForm
          ref="importForm"
          isNewUpload={isNewUpload}
          isTxt={isTxt}
          templateUrl={templateUrl}
          formData={formData}
          uniqueCode={uniqueCode || ""}
          setFormData={this.setFormData}
          clear={this.clear}
          saveUploadResults={this.saveUploadResults}
        />
        {showErrFile ? (
          endLoad && (
            <div className={styles.errMsgContent}>
              <p>
                成功上传{formData.successCount || 0}条，失败
                {formData.errCount || 0}条
              </p>
              {errFileUrl && (
                <a href={errFileUrl} target="_blank">
                  下载失败数据
                </a>
              )}
            </div>
          )
        ) : (
          <>
            {String(formData.successCount) != "" && (
              <p className={styles.successCount}>
                成功条数: {formData.successCount}
              </p>
            )}
            {formData.errArr.length > 0 && (
              <div className={styles.errArea}>
                <p key={new Date()} className={styles.errTit}>
                  <span className={styles.firstLabel}>失败原因: </span>
                  <span className={styles.firstRow}>{formData.errArr[0]}</span>
                </p>
                {formData.errArr.map((item, index) => {
                  return (
                    index > 0 && (
                      <p key={index} className={styles.errRow}>
                        {item}
                      </p>
                    )
                  );
                })}
              </div>
            )}
          </>
        )}
      </Modal>
    );
  }
}
