import { loadDetail, saveDetail, logout } from '../services/user';
import * as storage from '../utils/storage';
import { routerRedux } from 'dva/router';
import config from '../common/config';
import { getConfig } from 'framework/siteConfig';
import cookie from '../utils/cookie';
import { setLogoutState } from 'src/framework/layouts/BasicLayout/ClassicLayout/components/OverDateModal';
import nativeLogout from '../services/nativeLogout';
//
export default {
  namespace: 'user',
  state: {
    detail: null
  },
  effects: {
    * loadDetail({ callback }, { call, put }) {
      const detail = yield call(loadDetail);
      saveDetail(detail);
      yield put({ type: 'setDetail', payload: { detail } });
      callback && callback(detail);
    },
    * logout(_, { call, put }) {
      setLogoutState();
      const logoutFun = getConfig('logout');
      if (logoutFun) {
        yield call(logoutFun, logout, storage, cookie);
        storage.clear();
        cookie.clear();
        yield call(logout);
      } else {
        storage.clear();
        cookie.clear();
        yield call(logout);
        yield put(routerRedux.push('/user/login'));
        nativeLogout();
      }
    }
  },
  reducers: {
    setDetail(state, { payload }) {
      const { detail } = payload;
      return { ...state, detail }
    }
  },
  subscriptions: {},
};
