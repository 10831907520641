/* eslint-disable */
export const formatDateStr = (str) => { //根据字符串得到年月日时分秒
  const [ymd = '', hms = ''] = str.split(' ');
  let [year, month, day] = ymd.split('-');
  let [hour, minutes, seconds] = hms.split(':');
  year = parseInt(year) || 0;
  month = (parseInt(month) || 1) - 1;
  day = parseInt(day) || 0;
  hour = parseInt(hour) || 0;
  minutes = parseInt(minutes) || 0;
  seconds = parseInt(seconds) || 0;
  return { year, month, day, hour, minutes, seconds };
};

export const numFormat = (num) => { //小于10的数字前面加0
  return num < 10 ? `0${num}` : `${num}`;
};

export const date2String = (date) => {
  const year = date.getFullYear();
  const month = numFormat(date.getMonth() + 1);
  const day = numFormat(date.getDate());
  const hour = numFormat(date.getHours());
  const minutes = numFormat(date.getMinutes());
  const seconds = numFormat(date.getSeconds());
  return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
};

export const string2Date = (str) => {
  const { year, month, day, hour, minutes, seconds } = formatDateStr(str);
  const dt = new Date(0);
  dt.setFullYear(year);
  dt.setMonth(month);
  dt.setDate(day);
  dt.setHours(hour);
  dt.setMinutes(minutes);
  dt.setSeconds(seconds);
  dt.setMilliseconds(0);
  return dt;
};

// 获取间隔天数
export const diffDate = (start, end) => {
  let startTime = new Date(start); // 开始时间
  let endTime = new Date(end); // 结束时间
  if (endTime.getTime() - startTime.getTime() >= 0) {
    // console.log(endTime - startTime); // 毫秒数
    // console.log(Math.floor((endTime - startTime) / 1000)); // 秒数
    // console.log(Math.floor((endTime - startTime) / 1000 / 60)); // 分钟
    // console.log(Math.floor((endTime - startTime) / 1000 / 60 / 60)); // 小时
    // console.log(Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24)); // 天数
    return Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24) + 1;
  } else {
    message.toast('结束时间应大于开始时间');
  }
}

//获取某一天的前n天的年月日 getBeforeByDays('2021-05-25',5) ==> '2021-05-20'
export const getBeforeByDays = (ymd, days) => {
  const date = string2Date(ymd);
  const resDate = new Date(date * 1 - days * 24 * 60 * 60 * 1000);
  return date2String(resDate).split(' ')[0];
}

//获取某一天的前n个月的年月日 如果当月不存在同样的日期 往前一天递归
// getBeforeByMonths('2021-05-25',4) ==> '2021-01-25'
// getBeforeByMonths('2021-03-31',1) ==> '2021-02-28'
export const getBeforeByMonths = (ymd, months) => {
  const date = string2Date(ymd);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  for (let i = 0; i < months; i++) {
    if (month === 1) {
      year--;
      month = 12;
    } else {
      month--;
    }
  }
  const createNewDate = () => {
    const newDate = new Date(0);
    newDate.setFullYear(year);
    newDate.setMonth(month - 1);
    newDate.setDate(day);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  };
  let resDate = createNewDate();
  while (resDate.getDate() !== day) {
    day--;
    resDate = createNewDate();
  }
  return date2String(resDate).split(' ')[0];
};

// 获取某一天的前n年的年月日  如果当年不存在同样的日期 往前一天递归
// getBeforeByYears('2021-05-25',4) ==> '2017-05-25'
// getBeforeByYears('2020-02-29',1) ==> '2019-02-28'
export const getBeforeByYears = (ymd, years) => {
  const date = string2Date(ymd);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  year = year - years;
  const createNewDate = () => {
    const newDate = new Date(0);
    newDate.setFullYear(year);
    newDate.setMonth(month - 1);
    newDate.setDate(day);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
  };
  let resDate = createNewDate();
  while (resDate.getDate() !== day) {
    day--;
    resDate = createNewDate();
  }
  return date2String(resDate).split(' ')[0];
};