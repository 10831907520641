import React from 'react';
import styles from './ColumnCheckbox.less';
import classNames from 'classnames';
import {Checkbox} from 'components';
import _ from 'lodash';
//
export default class extends React.Component {
  static styles = styles;

  handleClick = _.throttle(() => {
    const {checked, onChange} = this.props;
    onChange(!checked);
  }, 200, {trailing: false});

  render() {
    const {checked, data} = this.props;
    return (
      <div onClick={this.handleClick} className={classNames(styles.checkboxWrap, checked && styles.checked)}>
        <Checkbox className={styles.checkbox} checked={checked}></Checkbox>
        <div className={styles.label}>{data.label}</div>
      </div>
    )
  }
}
