import React from 'react';
import { Radio } from 'element-react';
//
export default class extends React.Component {
  componentDidMount() {
    const { activeFirst, enums, onChange } = this.props;
    const value = this.getValue();
    if (activeFirst && !value) {
      const enumsData = enums.getData();
      const names = Object.keys(enumsData);
      onChange(enumsData[names[0]]);
    }
  }

  getValue = () => {
    const { value } = this.props;
    return typeof value === 'number' ? `${value}` : value;
  };

  render() {
    const { enums } = this.props;
    const enumsData = enums.getData();
    const names = Object.keys(enumsData);
    const props = {
      ...this.props
    };
    const { radioClassName, disabled, renderInner } = props;
    props.value = this.getValue();
    return (
      <Radio.Group {...props}>
        {names.map((name, index) => {
          const radioProp = { key: index };
          radioProp.className = radioClassName;
          radioProp.disabled = disabled;
          radioProp.value = enumsData[name];
          return (
            <Radio {...radioProp}>{renderInner ? renderInner(name, radioProp.value) : name}</Radio>
          );
        })}
      </Radio.Group>
    )
  }
}
