import cookies from 'react-cookies';
import config from 'framework/common/config';
export const clearAllCookie = () => {
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (let i = keys.length; i--;) {
      document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
    }
  }
};
export const setCookie = (key, value, setOptions = {}) => {
  const defaultOptions = {domain: config.domain, path: '/'};
  const options = {...defaultOptions, ...setOptions};
  cookies.save(key, encodeURIComponent(value), options);
};
export const getCookie = (key) => {
  return decodeURIComponent(cookies.load(key));
};
export const removeCookie = (key, setOptions = {}) => {
  const defaultOptions = {domain: config.domain, path: '/'};
  const options = {...defaultOptions, ...setOptions};
  cookies.remove(key, options)
};
export default {
  clear: clearAllCookie
}
