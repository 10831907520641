import React, { createRef } from 'react';
import Table from '../Table';
import styles from './index.less';
import { ajaxSync } from 'framework/utils/ajax';
// import nextTick from 'framework/utils/nextTick';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { BaseSearch, Icon, Message, CjmDatePicker } from 'components';
import CjmAdvanceSearch from '../AdvanceSearch';
import { Spin, Pagination, Tooltip } from 'antd';
import classNames from 'classnames';
import AdvanceSearchForm from '../AdvanceSearchForm';
import OperateButton from '../OperateButton';
import ExportModal from '../ExportModal';
import ImportModal from '../ImportModal';
import OperateLinkGroup from '../OperateLinkGroup';
import sleep from 'src/framework/utils/sleep';
//
const defaultPage = { current: 1, pageSize: 10, total: 0 };

function JSONParse(str) {
  let result = null;
  try {
    result = JSON.parse(str);
  } catch (e) {
    result = {};
  }
  return result;
}

function compare(val1, val2) {
  return val1 - val2;
};

// expandAdvanceSearch: 是否默认展开高级搜索，默认false
// datePicker: 是否在操作栏添加年份选择
// datePicker = {{
//   placeholder: "选择年",
//   selectionMode: "year",
//   valueKey: 'year',
//   defaultDate: (new Date()).getFullYear()
// }}
class List extends React.Component {
  static AdvanceSearchForm = AdvanceSearchForm;
  static OperateButton = OperateButton;
  static ExportModal = ExportModal;
  static ImportModal = ImportModal;
  static OperateLinkGroup = OperateLinkGroup;

  state = {
    tableData: [], //表格数据
    isLoading: false, //是否加载中
    keyWord: '', //搜索关键字
    condition: {}, //高级搜索条件
    page: { ...defaultPage }, //分页信息
    orderField: '',
    orderType: '',
    showAdvanced: false,
    selectedRows: [],
    pageSizeOptions: ['10', '20', '30', '40'],
    date: ''
  };

  advancedSearchRef = createRef(null);

  componentWillMount() {
    this.initList();
  }

  initList = () => {
    // isSinglePageSize 是否需要个性化的分页（如润达数据分析，每页显示3条数据）
    const { isSinglePageSize = {}, datePicker, expandAdvanceSearch } = this.props;
    const { page } = this.state;
    let pageSizeOptions = ['10', '20', '30', '40'];
    if (isSinglePageSize.flag && isSinglePageSize.singlePageSize) {
      // 个性化修改pageSize及pageSizeOptions
      page.pageSize = isSinglePageSize.singlePageSize;
      pageSizeOptions.unshift(isSinglePageSize.singlePageSize + '');
      pageSizeOptions = [...new Set(pageSizeOptions)].sort(compare);
    }
    this.setState(
      {
        page,
        pageSizeOptions,
        date: (datePicker && datePicker.defaultDate) || ''
      },
      function () {
        if (expandAdvanceSearch) {
          this.initAdvanceSearch();
        } else {
          this.loadData();
        }
      }
    );
  };

  initAdvanceSearch = () => {
    this.advancedSearchRef.current.toggleForm();
    this.advancedSearchRef.current.handleAdvanceSearch();
  }

  refresh = () => {
    this.setState({
      selectedRows: []
    })
    this.loadData();
  };

  // 自定义搜索
  refreshSearch = () => {
    const { page } = this.state;
    page.current = 1;
    this.setState({ page }, this.loadData);
  }

  getStateData = () => {
    return { ...this.state };
  };

  getSearchInfo = () => {
    const { datePicker } = this.props;
    const { keyWord, condition, date } = this.state;
    if (datePicker) {
      const { valueKey = 'date' } = datePicker || {}
      return { search: keyWord, advancedSearch: condition, [valueKey]: date }
    } else {
      return { search: keyWord, advancedSearch: condition }
    }
  };
  getAdvancedSearch = () => {
    const { condition } = this.state;
    let result = `{}`;
    try {
      result = JSON.stringify(condition);
    } catch (err) {
    }
    return result === '{}' ? '' : encodeURIComponent(result);
  };

  async loadData() {
    const { dataSource, beforeAjax, ajaxOptions, dataFormat } = this.props;
    const advancedSearch = !!this.getAdvancedSearch();
    let { ajaxMethod = 'get' } = this.props;
    const type = typeof dataSource;
    this.setLoadingState(true);
    const date1 = Date.now();
    let data = [];
    let total = 0;
    if (Array.isArray(dataSource)) {
      // dataSource是数组,直接作为数据显示
      data = dataSource;
      total = data.length;
    } else if (type === 'string') {
      // dataSource是string, 作为url请求得到数据,进行显示
      const params = this.getParam();
      let realDataSource = dataSource;
      let realParams = params;
      realParams.flag = (typeof params.flag !== 'undefined') ? params.flag : '1'; //普通搜索
      if (beforeAjax) {
        const backDetail = beforeAjax({ dataSource, params });
        realDataSource = backDetail.dataSource;
        realParams = backDetail.params;
      }
      const options = { ...ajaxOptions };
      if (!options.headers) {
        options.headers = {};
      } else if (Object.prototype.toString.call(ajaxSync.setHeaders) === '[object Function]') {
        ajaxSync.setHeaders(options.headers);
      }
      if (advancedSearch) {
        const advancedSearchParams = JSONParse(decodeURIComponent(this.getAdvancedSearch()));
        Object.keys(advancedSearchParams).forEach(key => {
          const val = advancedSearchParams[key];
          val !== '' && val !== undefined && val !== null && (realParams[key] = val);
        });
        ajaxMethod = 'formPost';
        realParams.flag = '0'; //高级搜索
      }
      // options.headers.advancedSearch = this.getAdvancedSearch();
      const { res } = await ajaxSync[ajaxMethod](realDataSource, realParams, options);
      // console.log(res, err);
      if (res && res.state !== 200) {
        Message.error(res.msg);
      }
      data = _.get(res, 'results.list', []) || [];
      data = dataFormat ? dataFormat(data, res) : data;
      total = _.get(res, 'results.pagination.total', data.length);
    } else if (type === 'function') {
      // dataSource是function, 执行后把返回值作为数据显示
      const params = this.getParam();
      data = await dataSource(params);
      total = data.length;
    }
    const date2 = Date.now();
    let times = 500 - (date2 - date1);
    times = times < 0 ? 0 : times;
    setTimeout(() => {
      //延迟显示数据
      const { onDataLoaded } = this.props;
      this.setLoadingState(false);
      this.setTableData(data);
      this.setTotal(total);
      onDataLoaded && onDataLoaded();
    }, times);
  }

  getParam = () => {
    const { page, keyWord, orderField, orderType, date } = this.state;
    let { ajaxParams = {}, datePicker } = this.props;
    const { current, pageSize } = page;
    if (typeof ajaxParams === 'function') {
      ajaxParams = ajaxParams(this);
    }
    const params = { current, pageSize, ...ajaxParams };
    if (keyWord) {
      params.search = keyWord;
    }
    if (orderField && orderType) {
      params.orderField = orderField;
      params.orderType = orderType;
    }
    if (datePicker) {
      const { valueKey = 'date' } = datePicker || {};
      params[valueKey] = date;
    }
    return params;
  };
  setLoadingState = state => {
    const { onLoadingStateChange } = this.props;
    this.setState({ isLoading: state });
    onLoadingStateChange && onLoadingStateChange(state);
  };

  setTableData = tableData => {
    const { isVerifyInitial } = this.props;
    if (isVerifyInitial) {
      //此处是对数据返回首字母大写做处理,便于处理导出 added by yanglei
      const newData = [];
      tableData.forEach(item => {
        const obj = {};
        for (const key in item) {
          const newKey = key.charAt(0).toLocaleLowerCase() + key.slice(1);
          obj[newKey] = item[key];
        }
        newData.push(obj);
      });
      tableData = newData;
    }
    this.setState({ tableData }, this.tabSelectRowsSync);
  };

  setTotal = total => {
    const { page } = this.state;
    page.total = total;
    this.setState({ page });
  };

  handlePageChange = (current, pageSize) => {
    const { page } = this.state;
    page.current = current;
    page.pageSize = pageSize;
    this.setState({ page });
    this.loadData();
  };
  handleSearch = async (value = '') => {
    const { page, selectedRows } = this.state;
    const { beforeSearch, ajaxParams } = this.props;
    page.current = 1;
    const newState = {};
    newState.selectedRows = [];
    newState.page = page;
    if (typeof value === 'string') {
      newState.keyWord = value;
      newState.condition = {};
    } else {
      newState.keyWord = '';
      newState.condition = value;
    }
    this.setState(newState, async () => {
      this.tabSelectRowsSync();
      beforeSearch && beforeSearch();
      await sleep(200);
      if ((ajaxParams && ajaxParams.current === 1) || !beforeSearch) {
        this.loadData();
      }
    });
  };
  handleSortChange = ({ column, sortType }) => {
    const { prop = '' } = column || {};
    this.setState({ orderField: prop, orderType: sortType }, this.loadData);
  };

  handleToggleForm = showAdvanced => {
    this.setState({ showAdvanced });
  };

  // 清除选中的多选选项
  clearSelection = (rows = []) => {
    const component = _.get(this, 'refs.table.refs.table');
    if (rows && rows.length > 0) {
      rows.forEach(row => {
        component.toggleRowSelection(row);
      });
    } else {
      component.clearSelection();
    }
  };

  reverseSelectRow = () => {
    const { tableData, selectedRows } = this.state;
    const result = tableData.filter(item => !selectedRows.includes(item));
    this.setState({ selectedRows: result }, this.tabSelectRowsSync)
    // console.log(tableData, selectedRows);
  };

  tabSelectRowsSync = () => {
    const tableComponent = _.get(this, 'refs.table.refs.table');
    if (tableComponent) {
      const { tableData, selectedRows } = this.state;
      const { rowKey } = this.props;
      const rows = tableData.filter(row => {
        const matchRow = selectedRows.find(sRow => rowKey(sRow) === rowKey(row));
        return !!matchRow;
      });
      tableComponent.setState({ selectedRows: rows });
    }
  };

  handleSelectChange = async selectedRows => {
    const { onSelectChange } = this.props;
    const rows = await this.setSelectedRows(selectedRows);
    onSelectChange && onSelectChange(rows);
  };

  setSelectedRows = selectedRows => {
    return new Promise(resolve => {
      const { rowKey } = this.props;
      const { selectedRows: localSelectedRows, tableData } = this.state;
      let newRows = [...localSelectedRows];
      newRows = newRows.filter(row => {
        const key = rowKey(row);
        const isInTable = !!tableData.find(tRow => rowKey(tRow) === key);
        const isInSelected = !!selectedRows.find(sRow => rowKey(sRow) === key);
        if (isInTable && !isInSelected) {
          return false;
        }
        return true;
      });
      selectedRows.forEach(row => {
        const key = rowKey(row);
        const isInRows = !!newRows.find(nRow => rowKey(nRow) === key);
        if (!isInRows) {
          newRows.push(row);
        }
      });
      this.setState({ selectedRows: newRows }, () => {
        resolve(newRows);
      });
    });
  };

  getSelectedRows = () => {
    const { tableData, selectedRows } = this.state;
    return selectedRows;
  };

  loadingIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  baseSearchRender = () => {
    const { baseSearchPlaceholder } = this.props;
    const { keyWord } = this.state;
    return (
      <div className={styles.baseSearchWrap}>
        <BaseSearch
          placeholder={baseSearchPlaceholder}
          value={keyWord}
          search={this.handleSearch}
          refresh={() => this.handleSearch('')}
          enterButton
        />
      </div>
    )
  }

  setDate = date => {
    const { beforeSearch } = this.props
    this.setState({
      date
    }, () => {
      this.refresh()
      beforeSearch && beforeSearch()
    })
  }

  getOperate = () => {
    const { operate, datePicker } = this.props;
    const { date } = this.state;

    return (
      <>
        {!!operate && operate}
        {datePicker && (
          <CjmDatePicker {...datePicker} value={date} onChange={this.setDate} />
        )}
      </>
    )
  }

  render() {
    const {
      columns,
      maxHeight,
      operate,
      baseSearch,
      tableKey = 'key',
      tableHeight,
      advanceSearchForm,
      advanceSearchOtherParams,
      baseSearchPlaceholder = '请输入关键字搜索',
      className,
      seletAllChange,
      rowKey,
      innerReplace,
      showSummary = false,
      onSelect,
      onRowClick,
      onExpand,
      onCurrentChange,
      rowClassName,
      noPaging,
      datePicker,
      expandAdvanceSearch,
      customColumns
    } = this.props;
    const { tableData, isLoading, page, showAdvanced, pageSizeOptions, keyWord, date } = this.state;
    const hasOperate = !!operate || !!datePicker;
    const listClass = classNames(
      'cjm-list',
      styles.listWrap,
      className,
      advanceSearchForm ? styles.hasAdvanceSearch : ''
    );
    return (
      <div className={listClass}>
        <div className={styles.header}>
          {hasOperate || (baseSearch && !advanceSearchForm) ? (
            <div className={styles.operateSearchWrap}>
              {hasOperate && <div className={classNames(styles.operateWrap, 'cjm-list-operate-Wrap')}>{this.getOperate()}</div>}
              {baseSearch && !advanceSearchForm && baseSearchPlaceholder.length <= 11 && this.baseSearchRender()}
              {baseSearch && !advanceSearchForm && baseSearchPlaceholder.length > 11 && (
                <Tooltip title={baseSearchPlaceholder} overlayStyle={{ width: `${baseSearchPlaceholder.length * 16}px`, maxWidth: 250 }} placement="bottom">
                  {this.baseSearchRender()}
                </Tooltip>
              )}
            </div>
          ) : null}
          {advanceSearchForm ? (
            <div
              className={classNames(styles.advanceSearchWrap, showAdvanced && styles.showAdvanced)}
            >
              <CjmAdvanceSearch
                ref={this.advancedSearchRef}
                placeholder={baseSearchPlaceholder}
                showAdvanced={showAdvanced}
                onToggleForm={this.handleToggleForm}
                search={this.handleSearch}
                dropform={advanceSearchForm}
                value={keyWord}
                expandadvancesearch={expandAdvanceSearch}
                advancesearchotherparams={datePicker ? { ...advanceSearchOtherParams, [datePicker.valueKey || 'date']: date } : advanceSearchOtherParams}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.tableWrap}>
          <Spin indicator={this.loadingIcon} spinning={isLoading}>
            <Table
              onRowClick={onRowClick}
              onCurrentChange={onCurrentChange}
              onExpand={onExpand}
              key={tableKey}
              showSummary={showSummary}
              ref="table"
              rowKey={rowKey}
              onSortChange={this.handleSortChange}
              className={styles.table}
              isLoading={isLoading}
              columns={columns}
              maxHeight={maxHeight}
              height={tableHeight}
              data={tableData}
              onSelectChange={this.handleSelectChange}
              seletAllChange={seletAllChange}
              onSelect={onSelect}
              rowClassName={rowClassName}
              customColumns={customColumns}
            />
          </Spin>
        </div>
        {innerReplace}
        {!noPaging && (
          <div className={classNames(styles.pageBarWrap, 'cjm-list-pagebar-wrap')}>
            <div className={styles.pageShortInfo}>共{page.total}条</div>
            <div className={styles.pageBar}>
              <Pagination
                pageSizeOptions={pageSizeOptions}
                showQuickJumper
                showSizeChanger
                defaultCurrent={1}
                current={page.current}
                pageSize={page.pageSize}
                total={page.total}
                onShowSizeChange={this.handlePageChange}
                onChange={this.handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  baseSearch: PropTypes.bool,
};
List.defaultProps = {
  baseSearch: true,
  customColumns: false//是否需要动态修改 columns
};
export default List;
