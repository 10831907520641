import React from "react";
import { Upload, Button } from "element-react";
import Message from "src/framework/components/base/Message";
import classNames from "classnames";
import api from "framework/common/api";
import uuid from "src/framework/utils/uuid";
import _ from "lodash";
import { getUploadUrl } from "framework/services/file";
import SparkMD5 from "./spark-md5.min.js";

import styles from "./index.less";
//
//@connect()
class UploadComponent extends React.Component {
  state = { fileName: "file", uploading: false, key: uuid(), uploadRes: null, loading: true };
  beforeUpload = async (file) => {
    const { name, size } = file;
    const md5 = await this.calculate(file);
    const res = await getUploadUrl({
      fileName: name,
      size,
      md5: md5,
      check: true
      // businessType: 2001
    });
    this.setState({
      uploadRes: res,
      fileName: file.name,
      uploading: true
    });
    return false;
    // this.setState({ fileName: file.name, uploading: true });
  };

  showError = (msg) => {
    msg && Message.error(msg);
  };
  // 文件md5
  calculate = (file) => {
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      let blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice;
      let chunkSize = 2097152;
      // read in chunks of 2MB
      let chunks = Math.ceil(file.size / chunkSize);
      let currentChunk = 0;
      let spark = new SparkMD5();

      fileReader.onload = function(e) {
        console.log("read chunk nr", currentChunk + 1, "of", chunks);
        spark.appendBinary(e.target.result); // append binary string
        currentChunk++;

        if (currentChunk < chunks) {
          loadNext();
        } else {
          resolve(spark.end());
        }
      };

      function loadNext() {
        var start = currentChunk * chunkSize,
          end = start + chunkSize >= file.size ? file.size : start + chunkSize;

        fileReader.readAsBinaryString(blobSlice.call(file, start, end));
      }

      loadNext();
    });
  };

  handleSuccess = (res, file, fileList) => {
    const { onSuccess, onError } = this.props;
    if (res && res.state == 200) {
      onSuccess && onSuccess(res, file);
    } else {
      onError ? onError(res) : this.showError(_.get(res, "msg"));
      this.setState({ key: uuid() });
    }
    this.setState({ uploading: false });
  };
  handleError = () => {
    this.setState({ uploading: false });
  };
  handleRemove = (file, fileList) => {
    const { onRemove, disabled } = this.props;
    !disabled && onRemove && onRemove(file);
  };
  submit = () => {
    this.refs.upload.submit();
  };

  fileUpload = (data) => {
    const { onSuccess, onError } = this.props;
    const { uploadRes } = this.state;
    this.setState({
      loading: false
    });
    const that = this;
    // 是否上传过
    if (uploadRes.needUpload) {
      new Promise((resolve, reject) => {
        let ajaxData = null;
        let httpMethod = "";
        if (uploadRes.httpMethod == "formPost") {
          var form = new FormData();
          form.append("file", data.file);
          form.append("key", uploadRes.formBody.key);
          form.append("token", uploadRes.formBody.token);
          ajaxData = form;
          httpMethod = "POST";

          var settings = {
            url: uploadRes.url,
            method: "POST",
            timeout: 0,
            processData: false,
            mimeType: "multipart/form-data",
            contentType: false,
            headers: uploadRes.headers,
            data: form
          };

          $.ajax(settings).done(function(response) {
            if (response) {
              response = JSON.parse(response);
              console.log(response);
              resolve({ err: null, res: response });
              that.handleSuccess(response.key);
              onSuccess && onSuccess({ state: 200, results: uploadRes.fileId }, data.file);
              that.setState({
                loading: true
              });
            }
          });
        } else {
          ajaxData = data.file;
          httpMethod = uploadRes.httpMethod;

          var settings = {
            url: uploadRes.url,
            method: httpMethod,
            timeout: 0,
            headers: uploadRes.headers,
            processData: false,
            data: ajaxData
          };

          $.ajax(settings).done(function(response) {
            if (response) {
              response = JSON.parse(response);
              console.log(response);
              resolve({ err: null, res: response });
              that.handleSuccess(response.key);
              onSuccess && onSuccess({ state: 200, results: uploadRes.fileId }, data.file);
              that.setState({
                loading: true
              });
            }
          });
        }
      });
    } else {
      that.handleSuccess(uploadRes.fileId);
      onSuccess && onSuccess({ state: 200, results: uploadRes.fileId }, data.file);
      that.setState({
        loading: true
      });
    }
  };

  render() {
    const {
      accept,
      value = [],
      tip,
      limit,
      action,
      listType,
      disabled = false,
      autoUpload = true,
      onExceed,
      otherParam = {}
    } = this.props;
    const { fileName, uploading, key } = this.state;
    const finalProps = {
      key,
      accept,
      limit,
      action,
      fileList: value,
      listType,
      disabled,
      autoUpload,
      tip: tip ? <div className="el-upload__tip">{tip}</div> : "",
      data: { ...{ name: fileName }, ...otherParam },
      onExceed: onExceed,
      onError: this.handleError,
      onSuccess: this.handleSuccess,
      onRemove: this.handleRemove,
      beforeUpload: this.beforeUpload,
      className: classNames(styles.upload, { [styles["disabled"]]: disabled }),
      httpRequest: this.fileUpload
    };
    if (uploading || value.length === limit) {
      finalProps.disabled = true;
    }
    return (
      <Upload {...finalProps} ref="upload">
        {this.state.loading ? (
          <Button disabled={finalProps.disabled} size="small" type="primary">
            点击上传
          </Button>
        ) : (
          <span>上传中...</span>
        )}
      </Upload>
    );
  }
}

UploadComponent.defaultProps = {
  action: `${api.FILE_UPLOAD}`,
  limit: 1
};
export default UploadComponent;
