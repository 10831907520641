import { parse } from 'framework/utils/url'
;

export default {
  namespace: 'global',
  state: {
    pageSize: { width: 500, height: 500 },
    contentStyle: { backgroundColor: '#ffffff' },
    location: { path: '', query: {} }
  },
  effects: {
    *resize(_, { put, select }) {
      const { innerWidth, innerHeight } = window;
      const { pageSize: { width, height } } = yield select(__ => __.global);
      if(innerWidth !== width || innerHeight !== height) {
        yield put({ type: 'setPageSize', payload: { width: innerWidth, height: innerHeight } });
      }
    },
    *contentStyleChange({ payload }, { put }) {
      const { contentStyle } = payload;
      yield put({ type: 'setContentStyle', payload: { contentStyle } });
    }
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload,  };
    },
    setPageSize(state, { payload = { width: 500, height: 500 } }) {
      const {innerWidth: width, innerHeight: height} = window;
      const pageSize = {width, height};
      return {...state, pageSize}
      // return { ...state, pageSize: payload };
    },
    setContentStyle(state, { payload }) {
      const { contentStyle } = payload;
      return { ...state, contentStyle };
    }
  },
  subscriptions: {
    setupHistory({ dispatch, history }) {
      history.listen((location) => {
        const { pathname, search } = location;
        const { query } = parse(search);
        dispatch({
          type: 'updateState',
          payload: { location: { path: pathname, query: query,  } },
        });
        setTimeout(() => dispatch({ type: 'setPageSize' }), 500);
        setTimeout(() => dispatch({ type: 'setPageSize' }), 1000);
        setTimeout(() => dispatch({ type: 'setPageSize' }), 1500);
      });
    }
    //
  },
};
