import React from 'react';
import styles from './index.less';
import classNames from 'classnames';
import Popover from 'src/framework/components/base/Popover';

export default class extends React.Component {
  state = {
    moreVisible: false
  };
  
  handleVisibleChange = (moreVisible) => {
    this.setState({ moreVisible });
  };
  
  handleMoreItemClick = (item) => {
    return () => {
      const { onClick } = item;
      onClick && onClick();
      this.setState({ moreVisible: false });
    };
  };
  
  renderMoreContent = () => {
    const { list, limit = 4 } = this.props;
    const links = list.filter((item, index) => index > limit - 2);
    return (
      <div className={styles.moreLinkList}>
        {links.map((item, index) => {
          const { href, children, target, title } = item;
          return (
            <a href={href} target={target} title={title} onClick={this.handleMoreItemClick(item)} className={styles.moreLinkItem} key={index}>
              <span className={styles.moreLinkItemInner}>{children}</span>
            </a>
          );
        })}
      </div>
    );
  };
  
  renderMore = () => {
    const { list, limit = 4 } = this.props;
    const { moreVisible } = this.state;
    if (list.length > limit) {
      return (
        <Popover visible={moreVisible} overlayClassName={styles.operatePopover}
                 onVisibleChange={this.handleVisibleChange} trigger="click"
                 content={this.renderMoreContent()} placement="bottomRight">
          <a className={classNames(styles.moreLink, styles.linkItem)}>
            <span>更多</span>
            <i className={classNames(styles.moreArrow, 'sc-icon-arrow-down')}></i>
          </a>
        </Popover>
      );
    }
    return null;
  };
  
  render() {
    const { list, limit = 4 } = this.props;
    let links = [];
    if (list.length > limit) {
      links = list.filter((item, index) => index <= limit - 2);
    } else {
      links = list;
    }
    return (
      <React.Fragment>
        {links.map((item, index) => {
          const { href, children, title, target, className, style, onClick } = item;
          return (
            <a key={index} href={href} target={target} title={title} className={classNames(className, styles.linkItem)} style={style} onClick={onClick}>{children}</a>
          );
        })}
        {this.renderMore()}
      </React.Fragment>
    )
  }
}
