import React from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Tooltip } from 'antd';
import { CjmForm } from 'framework/components/normal/Cjm/Form';
import { Icon } from 'components';
import styles from './index.less';

const Search = Input.Search;

class AdvanceSearch extends React.Component {
  state = { showAdvanced: false };

  toggleForm = () => {
    const { showAdvanced } = this.state;
    const { onToggleForm } = this.props;
    onToggleForm && onToggleForm(!showAdvanced);
    this.setState({ showAdvanced: !showAdvanced });
  };

  componentDidMount() {
    const { showAdvanced = false } = this.props;
    this.setState({ showAdvanced });
  }

  handleSearch = (value) => {
    const { search } = this.props;
    search && search(value);
    this.setState({ hasChangedText: false });
  };
  handleAdvanceSearch = () => {
    const { search } = this.props;
    const value = this.refs.advanceSearchForm.getData();
    search && search(value);
  };
  handleReset = () => {
    const { search } = this.props;
    const resetData = this.refs.advanceSearchForm.reset();
    search(resetData);
  };

  handleChange = e => {
    const value = e.target.value;
    if (value) {
      this.setState({ showClose: true, value, hasChangedText: true });
    } else {
      this.setState({ showClose: false, value, hasChangedText: true });
    }
  };

  render() {
    const { dropform: AdvanceSearchForm, placeholder = '请输入关键字搜索', advancesearchotherparams } = this.props;
    const { showAdvanced, } = this.state;
    const searchProps = { ...this.props };
    if (this.state.hasChangedText) {
      searchProps.value = this.state.value;
    }
    ['enterButton', 'onSearch', 'onChange', 'placeholder', 'style', 'search', 'refresh', 'onToggleForm', 'dropform', 'showAdvanced'].forEach(key => {
      delete searchProps[key];
    });
    return (
      <div className={styles['ant-advanced-container']}>
        <div>
          {!showAdvanced && placeholder.length <= 11 && (
            <Search enterButton allowClear style={{ width: 280 }} onSearch={this.handleSearch} placeholder={placeholder} {...searchProps} onChange={this.handleChange} />
          )}
          {!showAdvanced && placeholder.length > 11 && (
            <Tooltip title={placeholder} overlayStyle={{ width: `${placeholder.length*16}px`, maxWidth: 250 }} placement="bottom">
              <Search enterButton allowClear style={{ width: 280 }} onSearch={this.handleSearch} placeholder={placeholder} {...searchProps} onChange={this.handleChange} />
            </Tooltip>
          )}
          <a className="toggle-form-link" style={{ marginLeft: 8 }} onClick={this.toggleForm}>
            高级搜索 {showAdvanced ? (<Icon type="up" />) : (<Icon type="down" />)}
          </a>
        </div>

        <div className={showAdvanced ? styles.showStyle : styles.hideStyle}>
          <div className={styles['ant-advanced-search-form']}>
            <AdvanceSearchForm ref="advanceSearchForm" advanceSearchOtherParams={advancesearchotherparams} />
            <div style={{ textAlign: 'right', marginTop: '30px' }}>
              <Button type="primary" onClick={this.handleAdvanceSearch}>搜索</Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>重置</Button>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

AdvanceSearch.prototypes = {
  search: PropTypes.func,
};
export default AdvanceSearch;
