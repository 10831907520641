/*
 * @Author: duyaoyao
 * @Date: 2020-10-29 16:29:47
 * @LastEditors: duyaoyao
 * @LastEditTime: 2020-11-17 10:22:26
 */
import React from "react";
import { Modal, Button } from "components";
import SignatureCanvas from "react-signature-canvas";
import imageFormPost, { getImageData } from "src/framework/utils/imageFormPost";
import Image from "src/framework/components/base/Image";
import imageSrc from "src/framework/utils/imageSrc";

export default class extends React.Component {
  getShowCanvase = async () => {
    await this.refs.modal.show();
    const { value } = this.props;
    if (value) {
      const imgUrl = imageSrc(value);
      const imgData = await getImageData(imgUrl);
      this.refs.signatureCanvas.fromDataURL(imgData);
    }
  };
  cleanCanvase = () => {
    this.refs.signatureCanvas.clear();
  };
  submit = async () => {
    const { onChange } = this.props;
    const imageData = this.refs.signatureCanvas.toDataURL();
    if (!this.refs.signatureCanvas.isEmpty()) {
      const imgId = await imageFormPost(imageData);
      onChange(imgId);
    }
    this.refs.modal.hide();
  };

  render() {
    const { width = 500, height = 200, value, disabled } = this.props;

    return (
      <>
        <div>
          {!disabled && (
            <Button type="primary" onClick={this.getShowCanvase}>
              签名
            </Button>
          )}
          {value && <Image width={100} height={50} imgId={value} />}
        </div>

        <Modal
          width={width + 50}
          title="电子签名"
          ref="modal"
          okHandler={this.submit}
        >
          <div style={{ border: "dashed 1px #e3e3e3" }}>
            <SignatureCanvas
              ref="signatureCanvas"
              canvasProps={{ width: width, height: height }}
            />
          </div>
          <div
            style={{
              color: "#333",
              fontSize: 13,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 12
            }}
          >
            <div>请在虚线框内进行签名</div>
            <div>
              <Button type="danger" onClick={this.cleanCanvase}>
                重写
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
