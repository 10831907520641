const deepClone = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => deepClone(item));
  } else if (typeof obj === 'object' && obj !== null && obj.constructor === Object) {
    const newObj = {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      newObj[key] = deepClone(value);
    });
    return newObj;
  } else {
    return obj;
  }
}
export default deepClone;
