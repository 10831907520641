import React from 'react';
import defaultImage from 'framework/assets/images/no-data.png';
import classNames from 'classnames';
import styles from './index.less';
import { MessageBox as _MessageBox } from 'element-react';

export const MessageBox = _MessageBox;
//import {connect} from 'dva';
//
//@connect()
export default class extends React.Component {
  render() {
    const { imageSrc = defaultImage, tipText = '', className, style } = this.props;
    return (
      <div className={classNames(className, styles.messageView)} style={style}>
        <img className={styles.messageImage} src={imageSrc} />
        {tipText && <div className={styles.tipText}>{tipText}</div>}
      </div>
    )
  }
}
