import { ajaxSync } from 'framework/utils/ajax';
import { parse } from 'framework/utils/url';
import api from 'userCenter/common/api';
import _ from 'lodash';

function checkIsAdmin() {
  const href = window.location.href;
  let topHref = '';
  try {
    topHref = top.location.href;
  } catch (err) {
  }
  if (getUrlParam(href, 'isAdmin') == '1') {
    return true;
  }
  if (getUrlParam(topHref, 'isAdmin') == '1') {
    return true;
  }
  return false;
}

function getUrlParam(url, key) {
  let val = '';
  const { query } = parse(url);
  Object.keys(query).forEach(keyItem => {
    if (keyItem.toLocaleLowerCase() === key.toLocaleLowerCase()) {
      val = query[keyItem];
    }
  });
  return val;
}

export const setSystem = async (sysId, sysUrl) => {
  const isAdmin = checkIsAdmin();
  const { err, res } = await ajaxSync.post(`${api.sys.SET_VIEW_SYS}/${sysId}`, { sysId, sysUrl }, { headers: { isAdmin } });
  return { err, res };
};

export const setViewSystem = setSystem;
