import React from 'react';
import {connect} from 'dva';
//
@connect()
export default class extends React.Component {
  resizeTimeout = null;
  times = 0;
  mountTime = 0;
  bindResize = () => {
    window.addEventListener('resize', this.resizeTrigger);
    window.addEventListener('scroll', this.resizeTrigger);
  };
  unBindReesize = () => {
    window.removeEventListener('resize', this.resizeTrigger);
    window.removeEventListener('scroll', this.resizeTrigger);
  };
  resizeTrigger = () => {
    this.times = this.times + 1;
    this.times === 10 && this.didDispatchResize();
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => {
      this.didDispatchResize();
      this.times = 0;
    }, 150)
  };
  didDispatchResize = (() => {
    let winWidth = null;
    let winHeight = null;
    return () => {
      const now = Date.now();
      if (window.innerWidth !== winWidth || window.innerHeight !== winHeight || now - this.mountTime < 5000) {
        winWidth = window.innerWidth;
        winHeight = window.innerHeight;
        const {dispatch} = this.props;
        dispatch({type: 'global/resize'});
      }
    }
  })();

  componentDidMount() {
    this.mountTime = Date.now();
    this.bindResize();
    this.resizeTrigger();
  }

  componentWillUnmount() {
    this.unBindReesize();
  }

  render() {
    return null
  }
}
