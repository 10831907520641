import React from 'react';
import Button from 'framework/components/base/Button';
import classNames from 'classnames';
import styles from './index.less';
//
export default class extends React.Component {
  render() {
    const {type, icon, onClick, children, className, style,disabled, ...others} = this.props;
    const finalProps = {};
    finalProps.type = type || 'primary';
    finalProps.onClick = onClick;
    finalProps.className = classNames(styles.button, className);
    finalProps.style = style;
    finalProps.disabled = disabled;
    return (
      <Button {...finalProps} {...others}>
        {icon && <i className={classNames(styles.icon, icon)}></i>}
        {disabled===true?(<span className={styles.disabled}>{children}</span>):(
          <span className={styles.text}>{children}</span>
        )}
      </Button>
    )
  }
}
