import React from 'react';
import { Upload } from 'element-react';
import Modal from '../../../base/Modal';
import Message from '../../../base/Message';
import ImageItem from './ImageItem';
import styles from './index.less';
import config from 'framework/common/config';
import api from 'framework/common/api';
import classNames from 'classnames';
import Image from '../../../base/Image';
import triggerFormItemChange from 'src/framework/utils/triggerFormItemChange';

//
window.React = React;
export default class extends React.Component {
  state = { name: 'image.jpg', key: Date.now(), previewImage: '' };
  
  handleComplate = (res, file, fileList) => {
    const { results, state, msg } = res;
    if (state == 200) {
      //上传成功
      this.handleSuccess(results);
    } else {
      //上传失败
      Message.error(msg);
    }
    this.updateKey();
  };
  
  handleSuccess = results => {
    const { value, splitType } = this.props;
    if (!splitType && (typeof value === 'string' || typeof value === 'undefined' || (!value && typeof (value) != "undefined" && value != 0))) {
      this.setValue(results);
    } else if (Array.isArray(value)) {
      this.setValue([...value, results]);
    } else if (splitType) {
      this.setValue(value ? value + splitType + results : results);
    }
  };
  
  handleBeforeUpload = file => {
    return new Promise(resolve => {
      const { name } = file;
      this.setState({ name }, () => {
        resolve(file.type.indexOf('image') >= 0);
      });
    });
  };
  
  updateKey = () => {
    this.setState({ key: Date.now() });
  };
  
  getImageList = () => {
    const { value, splitType } = this.props;
    if (!splitType && (typeof value === 'string')) {
      return value === '' ? [] : [value];
    } else if (Array.isArray(value)) {
      return value;
    } else if (splitType && typeof value === 'string') {
      return value ? value.split(splitType) : [];
    }
    return [];
  };
  
  getUpload = () => {
    const { name, key } = this.state;
    const { maxNumber = 1, tip, disabled = false } = this.props;
    const imageList = this.getImageList();
    if (imageList.length >= maxNumber) {
      return null;
    }
    const props = {};
    props.accept = 'image/*';
    props.action = `${api.FILE_UPLOAD}`;
    props.listType = 'picture-card';
    props.data = { name };
    props.multiple = false;
    props.fileList = [];
    props.beforeUpload = this.handleBeforeUpload;
    props.onSuccess = this.handleComplate;
    props.disabled = disabled;
    return (
      <Upload
        className={classNames(styles.uploadWrap, 'image-uploader-wrap')}
        key={`${key}`}
        {...props}
        tip={<div className="el-upload__tip">{tip}</div>}
      >
        <i className="el-icon-plus" />
      </Upload>
    );
  };
  handleRemove = index => {
    return () => {
      const { value, splitType } = this.props;
      if (splitType && typeof value === 'string') {
        const vArr = value.split(splitType);
        vArr.splice(index, 1);
        this.setValue(vArr.join(splitType));
      } else if (typeof value === 'string') {
        this.setValue('');
      } else if (Array.isArray(value)) {
        this.setValue(value.filter((item, sIndex) => sIndex !== index));
      }
    };
  };
  
  triggerChange = () => {
    const trigger = () => {
      const uploader = this.refs.uploader;
      if (uploader) {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('onchange', true, false);
        uploader.dispatchEvent(event);
        triggerFormItemChange(uploader);
      }
    };
    trigger();
    setTimeout(trigger, 200);
  };
  
  setValue = value => {
    const { onChange } = this.props;
    onChange && onChange(value);
    // window.ciu = this;
    this.triggerChange();
  };
  handlePreview = item => {
    return (imgUrl) => {
      if (imgUrl && typeof imgUrl === 'string') {
        item = imgUrl;
      }
      const addPrefix = typeof item === 'string' && item.indexOf('http') < 0 && item.indexOf('/') < 0;
      //const imageSrc = addPrefix ? `${config.fileDomain}/${item}` : item;
      const imageSrc = item;
      this.setState({ previewImage: imageSrc });
      this.refs.modal.show();
    };
  };
  
  handleItemChange = (index) => {
    return (newValue) => {
      const { value, splitType, onChange } = this.props;
      if (typeof onChange === 'function') {
        if (splitType && typeof splitType === 'string' && typeof value === 'string') {
          const valueList = value.split(splitType);
          valueList[index] = newValue;
          const setValue = valueList.filter(Boolean).join(splitType);
          onChange(setValue);
        } else if (!splitType && typeof value === 'string') {
          onChange(newValue);
        } else if (Array.isArray(value)) {
          value[index] = newValue;
          onChange([].concat(value));
        }
      }
    };
  };
  
  getImageItem = (item, index) => {
    const { iconLinks, disabled, customImageItemFn } = this.props;
    const props = {
      key: `${item}-${index}`,
      onRemove: this.handleRemove(index),
      onPreview: this.handlePreview(item),
      onChange: this.handleItemChange(index),
      src: item, iconLinks,
      disabled: disabled
    };
    if (customImageItemFn && (typeof customImageItemFn == 'function')) {
      return customImageItemFn(item, index, props);
    }
    return <ImageItem {...props} />;
  };
  
  okHandler = callback => {
    callback();
    this.refs.modal.hide();
  };
  
  render() {
    const { className, disabled } = this.props;
    const { previewImage = '' } = this.state;
    const preview = {
      imgId: previewImage.indexOf('/') >= 0 ? '' : previewImage,
      src: previewImage.indexOf('/') >= 0 ? previewImage : '',
    };
    return (
      <div ref="uploader" className={className}>
        {this.getImageList().map(this.getImageItem)}
        {!disabled && this.getUpload()}
        <Modal title="预览" ref="modal" okHandler={this.okHandler}>
          {/* <img className={styles.previewImage} src={this.state.previewImage} /> */}
          <Image imgId={preview.imgId} src={preview.src} className={styles.previewImage} />
        </Modal>
      </div>
    );
  }
}
