import React from "react";
import { CjmForm } from "components";
import styles from "./ImportForm.less";
import CjmUpload from "framework/components/normal/Upload";
import NewUpload from "framework/components/normal/NewUpload";
import { prefix } from "framework/common/config";
import api from "src/sites/base/config/api";

// const { required } = CjmForm.rules;
//
export default class extends React.Component {
  state = {
    key: Date.now(),
    form: {}
    // rules: {
    //   excel: [required],
    // },
  };

  getExcelParams = () => {
    const { form } = this.state;
    if (form.excel) {
      return {
        fileName: JSON.parse(form.excel)[0].name,
        uniqueCode: JSON.parse(form.excel)[0].code,
        fileId: JSON.parse(form.excel)[0].code
      };
    }
  };

  setFormData = (fieldName) => {
    return (fieldValue) => {
      const { form } = this.state;
      form[fieldName] = fieldValue;
      this.setState({ form });
    };
  };

  valid = async () => {
    const { form } = this.state;
    const result = await this.refs.form.validate();
    return result;
  };

  getExcelData = () => {
    const { form } = this.state;
    if (form.excel) {
      return JSON.parse(form.excel);
    }
    return [];
  };
  clearExcel = () => {
    const { form } = this.state;
    form.excel = "";
    // console.log(form.excel);
    this.setState({ form });
  };
  removeExcel = (file) => {
    const { clear } = this.props;
    let excels = this.getExcelData();
    excels = excels.filter((excel) => excel.url !== file.url);
    this.setFormData("excel")(JSON.stringify(excels));
    const { formData } = this.props;
    formData.errMsg = "";
    clear && clear();
  };
  setExcelData = (res, file) => {
    const { saveUploadResults } = this.props;
    const name = file.name;
    const code = `${res.results}`;
    saveUploadResults(code);
    const excel = { name, code };
    const excels = this.getExcelData();
    excels.push(excel);
    this.setFormData("excel")(JSON.stringify(excels));
    const { formData } = this.props;
    formData.errMsg = "";
  };
  render() {
    let { formData, uniqueCode, templateUrl, isNewUpload ,isTxt} = this.props;
    const { form, rules } = this.state;
    if (!templateUrl) {
      templateUrl = `${prefix}${api.fileManage.excelDownLoad}?uniqueCode=` + uniqueCode;
    }
    return (
      <CjmForm
        model={formData}
        labelPosition="top"
        className={styles.upload}
        ref="form"
        rules={rules}
      >
        {!isTxt && <div>
          &nbsp;&nbsp;文件下载{" "}
          <a href={templateUrl} target="_blank">
            &nbsp;&nbsp;&nbsp;点击下载导入模板
          </a>
        </div>}
        <CjmForm.Item label="文件上传" required className={styles.selectFile} prop="excel">
          {isNewUpload ? (
            <NewUpload
              key={form.excel}
              accept={isTxt?".txt":".xls,.xlsx"}
              value={this.getExcelData()}
              // limit={1}
              onRemove={this.removeExcel}
              onSuccess={this.setExcelData}
            />
          ) : (
            <CjmUpload
              key={form.excel}
              accept=".xls,.xlsx"
              value={this.getExcelData()}
              // limit={1}
              onRemove={this.removeExcel}
              onSuccess={this.setExcelData}
            />
          )}
        </CjmForm.Item>
        <div className={styles.errMsg}>{formData.errMsg}</div>
      </CjmForm>
    );
  }
}
