import React from 'react';
import { DatePicker } from 'element-react';
import moment from 'moment';
import classNames from 'classnames';
import './index.less';
//
export default class extends React.Component {
  getValue = () => {
    const { value } = this.props;
    if (value) {
      const isShowTime = this.getIsShowTime();
      const format = isShowTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
      return new Date(moment(value, format).valueOf());
    }
    return null;
  };

  handleChange = value => {
    const { onChange } = this.props;
    const format = this.getValueFormat();
    onChange(value ? moment(value).format(format) : '');
  };

  getIsShowTime = () => {
    const { props } = this;
    let { isShowTime } = props;
    if (!isShowTime && props.format && props.format.toLocaleLowerCase().indexOf('hh:mm:ss') >= 0) {
      isShowTime = true;
    }
    return !!isShowTime;
  };

  getValueFormat() {
    const isShowTime = this.getIsShowTime();
    const { selectionMode } = this.props;
    if (isShowTime) {
      return 'YYYY-MM-DD HH:mm:ss';
    }
    if (selectionMode === 'month') {
      return 'YYYY-MM';
    }
    if (selectionMode === 'year') {
      return 'YYYY';
    }
    return 'YYYY-MM-DD';
  }

  getPickerFormat(props) {
    if (props.isShowTime) {
      return 'yyyy-MM-dd HH:mm:ss';
    }
    if (props.selectionMode === 'month') {
      return 'yyyy-MM';
    }
    if (props.selectionMode === 'year') {
      return 'yyyy';
    }
    return 'yyyy-MM-dd';
  }

  render() {
    const { props } = this;
    const defaultProps = {};
    const finalProps = { ...defaultProps, ...props };
    finalProps.isShowTime = this.getIsShowTime();
    finalProps.format = this.getPickerFormat(finalProps);
    finalProps.value = this.getValue();
    finalProps.onChange = this.handleChange;
    finalProps.isDisabled = finalProps.disabled;
    const { className, placeholder } = this.props;
    return (
      <div className={classNames(className, 'cjm-date-picker')}>
        <DatePicker {...finalProps} placeholder={placeholder ? placeholder : '请选择时间'} />
      </div>
    );
  }
}
