import React from 'react';
import styles from './index.less';
import _ from 'lodash';

//
export const createAdvanceSearchForm = (formData, defaultProps) => {
  let initFormData = formData;

  class AdvanceSearchForm extends React.Component {
    styles = styles;
    state = {
      form: {}
    };
    getDefaultFormData = () => {
      return {};
    };

    static setInitFormData = (formData) => {
      initFormData = formData;
    };

    componentWillMount() {
      this.reset(initFormData);
    }

    getData = () => {
      return this.state.form;
    };

    reset = (resetData) => {
      const { onSetFormData } = this.props;
      const formData = resetData ? resetData : this.getDefaultFormData();
      this.setState({ form: formData });
      onSetFormData && onSetFormData(formData);
      this.handleReset && this.handleReset();
      return formData;
    };

    handleChangeTime = (type) => (v) => {
      const value = v;
      let startTime, endTime;
      if (Array.isArray(value)) {
        [startTime, endTime] = value;
      } else {
        [startTime, endTime] = value.split(/[~]/).map(x => x.trim());
      }
      this.setState({
        [`${type}Time`]: v
      });
      this.setFormData(`${type}StartTime`)(startTime);
      this.setFormData(`${type}EndTime`)(endTime);
    }

    setFormData = (fieldName) => {
      return (eventOrValue) => {
        const value = this.getValue(eventOrValue);
        const { form } = this.state;
        form[fieldName] = value;
        this.setState({ form }, () => {
          const { onSetFormData } = this.props;
          onSetFormData && onSetFormData(form);
        });
      };
    };
    getValue = (eventOrValue) => {
      const targetValue = _.get(eventOrValue, 'target.value');
      if (typeof eventOrValue === 'object' && typeof targetValue !== 'undefined') {
        return targetValue;
      }
      return eventOrValue;
    };

    render() {
      return null
    }
  }

  if (defaultProps) {
    AdvanceSearchForm.defaultProps = defaultProps;
  }

  return AdvanceSearchForm;
};
const advanceSearchForm = new createAdvanceSearchForm()

export default advanceSearchForm;
