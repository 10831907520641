import React from 'react';
import {Table} from 'antd';
import { Resizable } from 'react-resizable';
import PropTypes from 'prop-types';
import {ajaxSync} from 'framework/utils/ajax';
import {AdvanceSearch, BaseSearch} from 'components';
import 'react-resizable/css/styles.css'
import styles from './index.less'

const FLAG = {'高级搜索': '0', '普通搜索': '1'};

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};
class CjmList extends React.PureComponent {
  components = {
    header: {
      cell: ResizeableTitle,
    },
  }

  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      data: {
        list: [],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 10,
        },
      },
      options: {},
    };
  }

  async componentDidMount() {
     const {children} = this.props;
     this.setState({columns: children})
     this.getTableData();
  }

  async getTableData(options) {
    const {remoteUrl} = this.props;
    if(options){
      options.showTotal = null;
      options.showSizeChanger = null;
      options.showQuickJumper = null;
    }
    try {
      const {err, res} = await ajaxSync.get(remoteUrl, options);
      if (err) {
        // error(res.msg)
        console.error(err);
      } else if (res) {
        const {list, pagination} = res.results;
        this.setState({
          data: {
            list,
            pagination,
          },
        });
      }
    } catch (err) {
      // error(err)
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    const {options} = this.state;
    const data = {
      ...pagination,
      ...options,
    }
    this.getTableData(data, filters, sorter)
  };

  handleSearch = (search) => {
    const{data:{pagination}} = this.state;
    const options = {
      search,
      flag: FLAG['普通搜索'],
      ...pagination,
    }
    this.setState({options})
    this.getTableData(options);
  };

  handleAdvanceSearch = (search) => {
    const{data:{pagination}} = this.state;
    const options = {
      search,
      flag: FLAG['高级搜索'],
      ...pagination,
    }
    this.setState({options})
    this.getTableData(options);
  };

  refresh = () => {
    const {options} = this.state;
    this.getTableData( options );
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  render() {
    const {
      rowKey,
      dropform,
      searchType,
      extraAction,
    } = this.props;
    const {
      data: { list, pagination },
    } = this.state;
    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: true,
      showTotal: total => `共 ${total} 条`,
      ...pagination,
    };
    let resizeColumns = [];
    const _this = this;
    const {columns} = this.state;
    if(columns.length > 0) {
      resizeColumns = columns.map((child, index) => {
        return React.cloneElement(child, {
          ...child,
          onHeaderCell: child => ({
            width: child.width,
            onResize: _this.handleResize(index),
          }),
        });
      })
    }

    return (
      <React.Fragment>
        <div className={styles.listHeader}>
          <div className={styles.extraAction}>
            {extraAction}
          </div>
          {searchType === 'advance' ? (
            <AdvanceSearch
              dropform={dropform}
              search={this.handleAdvanceSearch}
            />
          ) : (
            <div className={styles.listBaseSearch}>
              <BaseSearch enterButton search={this.handleSearch} refresh={this.refresh} />
            </div>
          )}
        </div>

        <Table
          bordered
          components={this.components}
          rowKey={rowKey || 'key'}
          dataSource={list}
          pagination={paginationProps}
          onChange={this.handleTableChange}
          scroll={{ x: 1200, y: 600 }}
          {...this.props}
        >
          {resizeColumns}
        </Table>
      </React.Fragment>
    );
  }
}
CjmList.propTypes = {
  remoteurl: PropTypes.string,
  searchType: PropTypes.string,
};
CjmList.defaultProps = {
  remoteurl: '',
  searchType: '',
};
export default CjmList;
