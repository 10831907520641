import * as storage from '../utils/storage';
import { ajaxSync } from '../utils/ajax';
import api from 'src/framework/common/api';
import asyncGet from './asyncGet';
import _ from 'lodash';
// 获取所有省份
export const getProvinces = async () => {
  const storageProvinces = storage.get('provinces');
  if (storageProvinces && storageProvinces.length > 0) {
    return storageProvinces;
  } else {
    const { err, res } = await ajaxSync.get(api.region.GET_PRIVINCE);
    if (err) {
      console.error(err);
    } else if (res) {
      storage.set('provinces', res.results);
      return res.results;
    }
    return [];
  }
};
// 获取行政地区子地区，根据父行政地区编码
export const getChildAreas = async parentAreaCode => {
  const key = `areas_${parentAreaCode}`;
  const storageAreas = storage.get(key);
  if (storageAreas && storageAreas.length > 0) {
    return storageAreas;
  }
  const { err, res } = await ajaxSync.get(`${api.region.GET_CHILDREN}/${parentAreaCode}`);
  if (err) {
    console.error(err);
  } else if (res) {
    storage.set(key, res.results);
    return res.results;
  }
  return [];
};

// 根据行政地区编码 获取行政地区名称
export const getAreaDetail = async (areaCode = '') => {
  const key = `save_area_${areaCode}`;
  let detail = storage.get(key);
  if (!detail && areaCode) {
    const { res } = await ajaxSync.get(api.region.GET_DETAIL, { areaCode });
    detail = _.get(res, 'results');
    storage.set(key, detail ? detail : 'no-data');
  }
  if (detail === 'no-data') {
    return null;
  }
  return detail;
};
// (批量)根据新增地区编码 获取行政地区名称
export const getAreasDetail = async (areaCodes = []) => {
  let details = [];
  for (let i = 0, len = areaCodes.length; i < len; i++) {
    const detail = await getAreaDetail(areaCodes[i]);
    detail && details.push(detail);
  }
  return details;
};

//根据行政地区编码获取所有父级行政地区编码
export const getAllParentsRegion = (() => {
  const save = {};
  return (areaCode) => {
    if (!(typeof areaCode === 'string' && areaCode)) {
      return [];
    }
    if (save[areaCode]) {
      return save[areaCode];
    }
    let results = asyncGet(api.region.GET_ALL_PARENTS, { areaCode });
    results = _.get(results, 'results');
    const [
      province, provinceName, //省
      city, cityName, //市
      county, countyName, //区
      street, streetName, //街道
      village, villageName //社区
    ] = [
      _.get(results, 'province'), _.get(results, 'provinceName'),
      _.get(results, 'city'), _.get(results, 'cityName'),
      _.get(results, 'county'), _.get(results, 'countyName'),
      _.get(results, 'street'), _.get(results, 'streetName'),
      _.get(results, 'village'), _.get(results, 'villageName'),
    ];
    const allRegions = [];
    if (province && provinceName) {
      allRegions.push({ areaCode: province, cityName: provinceName });
    }
    if (city && cityName) {
      allRegions.push({ areaCode: city, cityName: cityName });
    }
    if (county && countyName) {
      allRegions.push({ areaCode: county, cityName: countyName });
    }
    if (street && streetName) {
      allRegions.push({ areaCode: street, cityName: streetName });
    }
    if (village && villageName) {
      allRegions.push({ areaCode: village, cityName: villageName });
    }
    if (allRegions.length > 0) {
      save[areaCode] = allRegions;
    }
    return allRegions;
  };
})();