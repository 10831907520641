/*
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2020-06-03 12:52:45
 * @LastEditors: yanglei
 * @LastEditTime: 2020-08-10 16:50:16
 */
import React from "react";
import { CjmForm } from "components";
import ColumnCheckbox from "./ColumnCheckbox";
import EnumRadio from "framework/components/normal/Cjm/EnumRadio";
import Enums from "framework/Enums";
//
const rangeEnum = new Enums("导出范围")
  .set("导出全部", "1")
  .set("导出选中", "2");
//
export default class extends React.Component {
  handleExportMetadataChange = column => {
    return isChecked => {
      const { formData, setFormData, columns } = this.props;
      let { exportMetadata } = formData;
      // console.log(isChecked, exportMetadata, column);
      if (isChecked && !exportMetadata.includes(column.prop)) {
        exportMetadata.push(column.prop);
      }
      if (!isChecked && exportMetadata.includes(column.prop)) {
        exportMetadata = exportMetadata.filter(item => item !== column.prop);
      }
      const sortMeta = [];
      columns.forEach(item => {
        if (exportMetadata.includes(item.prop)) {
          sortMeta.push(item.prop);
        }
      });
      setFormData("exportMetadata")(sortMeta);
    };
  };

  render() {
    const { columns, formData, setFormData, exportRangeEnum } = this.props;
    return (
      <CjmForm model={formData} labelPosition="top">
        <CjmForm.Item label="自定义导出列" prop="exportMetadata">
          <div className={ColumnCheckbox.styles.checkboxGroup}>
            {columns.map((item, index) => {
              const checked = formData.exportMetadata.includes(item.prop);

              const key = `${item.label}=${item.prop}-${index}`;
              return (
                <ColumnCheckbox
                  key={key}
                  checked={checked}
                  onChange={this.handleExportMetadataChange(item)}
                  data={item}
                />
              );
            })}
          </div>
        </CjmForm.Item>

        <CjmForm.Item label="导出范围" prop="range">
          <EnumRadio
            enums={exportRangeEnum ? exportRangeEnum : rangeEnum}
            value={formData.range}
            onChange={setFormData("range")}
          />
        </CjmForm.Item>
      </CjmForm>
    );
  }
}
