import React, { forwardRef } from 'react';
import { CjmForm } from 'components';

const { Item } = CjmForm;
function GeneralForm({itemList = [], model = {}, rules = {}, labelWidth="100px", changeValue}, ref) {

  const getOtherRules = () => {
    const newRules = {};
    itemList.forEach(item => {
      item.rules && (newRules[item.key] = item.rules);
    });

    return { ...newRules, ...rules };
  }

  return (
    <CjmForm ref={ref} model={model} rules={getOtherRules()} labelWidth={labelWidth}>
      {itemList.map(item => {
        const { label, key, value, onChange, Component, render, ...other } = item;
        return render ? render(item) : (
          <Item label={label} key={key} prop={key}>
            {Component ? (
            <Component 
              value={value ? value : model[key]} 
              onChange={onChange ? onChange : changeValue(key)} 
              {...other}
              propkey={key}
            />
            ) : (value || model[key] ||'') } 
          </Item>
        )
      })}
    </CjmForm>
  )
}

export default forwardRef(GeneralForm);