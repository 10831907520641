import React from "react";
import config from "framework/common/config";
import classNames from "classnames";
import styles from "./ImageItem.less";
import Image from "../../../base/Image";
import imageChoose from "./imageChoose";
import { addClass, removeClass } from "src/framework/utils/dom";
import sleep from "src/framework/utils/sleep";
import Modal from "src/framework/components/base/Modal";
import Viewer from "viewerjs";
import imageSrc from "src/framework/utils/imageSrc";

//是否是移动端
const isMobile = () => {
  const ua = navigator.userAgent.toLocaleLowerCase();
  return (
    ua.indexOf("mobile") >= 0 ||
    ua.indexOf("android") >= 0 ||
    ua.indexOf("ios") >= 0
  );
};
//
export default class extends React.Component {
  state = {
    viewer: null
  };
  componentDidMount() {
    this.initBodyClass();
    if (isMobile()) {
      var img = document.createElement("img");
      img.src = imageSrc(this.props.src);
      const viewer = new Viewer(img, {});
      this.setState({
        viewer
      });
    }
  }

  componentWillUnmount() {
    this.initBodyClass();
  }

  initBodyClass = async () => {
    if (isMobile()) {
      await sleep(100);
      const imgList = document.querySelectorAll(`.${styles.imageItem}`);
      if (imgList.length > 0) {
        addClass(document.body, styles.mobileBody);
      } else {
        removeClass(document.body, styles.mobileBody);
      }
    }
  };

  handleUploadClick = async () => {
    const imgId = await imageChoose();
    const { onChange } = this.props;
    onChange(imgId);
  };

  handleRemoveClick = () => {
    const { onRemove } = this.props;
    if (isMobile()) {
      Modal.confirm({
        title: "提示",
        content: "确定要删除这张图片?",
        onOk: () => {
          onRemove && onRemove();
        }
      });
    } else {
      onRemove && onRemove();
    }
  };
  preView = () => {
    if (isMobile()) {
      this.state.viewer.show();
    } else {
      this.props.onPreview();
    }
  };

  render() {
    const { src, onRemove, onPreview, iconLinks, disabled } = this.props;
    const { preView } = this;
    const addPrefix =
      typeof src === "string" &&
      src.indexOf("http") < 0 &&
      src.indexOf("/") < 0;
    //const imageSrc = addPrefix ? `${config.fileDomain}/${src}` : src;
    const imageSrc = src || "";
    const imgObj = {
      imgId: imageSrc.indexOf("/") >= 0 ? "" : imageSrc,
      src: imageSrc.indexOf("/") >= 0 ? imageSrc : ""
    };
    return (
      <div className={classNames(styles.imageItem, "imageItem")}>
        {/* <img src={imageSrc} /> */}
        <Image imgId={imgObj.imgId} src={imgObj.src} />
        <div className={styles.cover}>
          {iconLinks ? (
            iconLinks(this)
          ) : (
            <React.Fragment>
              <i
                onClick={this.handleUploadClick}
                className={classNames(styles.linkIcon, "sc-icon-upload")}
              ></i>
              <i
                onClick={preView}
                className={classNames(styles.linkIcon, "sc-icon-search")}
              />
              {!disabled && (
                <i
                  onClick={this.handleRemoveClick}
                  className={classNames(styles.linkIcon, "sc-icon-delete")}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}
