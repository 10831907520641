import React from 'react';
import CjmList from '../List';
import {createAdvanceSearchForm} from '../AdvanceSearchForm';
import createStore from 'framework/utils/createStore';

const store = createStore('historyListStore');

//
function getClickLink(event) {
  let link = event.target;
  while (link && link.tagName.toLocaleLowerCase() !== 'a') {
    link = link.parentElement;
  }
  return link;
}

function isMenuLink(link) {
  const menuLinks = [];
  const lks = document.querySelectorAll('.menu-box-wrap a');
  for (let i = 0, len = lks.length; i < len; i++) {
    menuLinks.push(lks[i]);
  }
  return menuLinks.includes(link);
}

document.body.addEventListener('click', function (event) {
  const link = getClickLink(event);
  if (link) {
    const href = link.getAttribute('href');
    if (isMenuLink(link) && href.indexOf('#/') === 0 && href !== window.location.hash) {
      store.eventBus.emit('clear');
    }
  }
});
//
/**
 * 用法
 * import {history} from components;
 * // matchPath是匹配的url hash 地址   CjmList和原来的用法一样
 * const CjmList = history('/matchPath');
 *
 * */
//
export default (matchPath) => {

  let list = null;
  let listState = null;
  let fromData = null;

  const onDataLoaded = function () {
    if (list) {
      listState = {...list.state};
    }
  };
  const onSetFormData = function (data) {
    fromData = data;
  };
  const clear = function () {
    list = null;
    listState = null;
    fromData = null;
  };
  store.eventBus.on('clear', clear);

  class HistoryList extends CjmList {
    static AdvanceSearchForm = createAdvanceSearchForm(fromData, {onSetFormData});

    constructor(prop) {
      super(prop);
      HistoryList.AdvanceSearchForm.setInitFormData(fromData);
      list = this;
    }

    componentWillMount() {
      if (listState) {
        this.setState({...listState, tableData: [], selectedRows: []}, () => {
          setTimeout(this.refresh, 200);
        });
      } else {
        this.initList();
      }
    }

    componentWillUnmount() {
      let needClear = true;
      if (Array.isArray(matchPath)) {
        matchPath.some(item => {
          if (typeof item === 'string' && window.location.hash.replace(/^#/, '').indexOf(item) === 0) {
            needClear = false;
            return true;
          }
        });
      } else if (typeof matchPath === 'string' && matchPath) {
        if (window.location.hash.replace(/^#/, '').indexOf(matchPath) === 0) {
          needClear = false;
        }
      }
      if (needClear) {
        clear();
      }
    }
  }


  HistoryList.defaultProps = {
    baseSearch: true,
    onDataLoaded,
  };
  return HistoryList;
}
