import React, { Component } from 'react';
import api from 'framework/common/api';
import config from 'framework/common/config';

import Wangeditor from './wangEditor';

function noop() { };

/**
 * RcWangEdite 组建
 */
export default class RcWangEdite extends Component {

  constructor(props) {
    super(props);
    this.state = {
      html: ''
    };
    this.containerRef = React.createRef();
    console.error(`--------------RcEditor即将在系统内去掉,请改用Ueditor----------------`);
  }

  componentDidMount = () => {
    const div = this.containerRef.current;
    const editor = new Wangeditor(div);
    this.editor = editor;
    this.setCustomConfig();
    editor.create();
    this.setHtml();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { disabled, value, defaultValue, customConfig } = this.props;
    const editor = this.editor;
    if (disabled !== prevProps.disabled) {
      editor.$textElem.attr('contenteditable', !disabled);
    }
    if (value !== prevProps.value || defaultValue !== prevProps.defaultValue) {
      this.setHtml();
    }
    if (customConfig !== prevProps.customConfig) {
      this.setCustomConfig();
    }
  }

  handleUploadSuccess = (insertImg, result, editor) => {
    const url = `${config.fileDomain}/${result.results}`;
    insertImg(url);
  };

  onChange = content => {
    const html = content.replace(/<p><br><\/p>/g, '');
    this.setState({ html });
    this.props.onChange(html);
    this.props.customConfig.onchange && this.props.customConfig.onchange(html);
  }

  setHtml = () => {
    const { value, defaultValue } = this.props;
    const html = value === undefined ? defaultValue : value;
    this.editor.txt.html(html);
    this.setState({ html });
  }

  setCustomConfig = () => {
    const { customConfig } = this.props;
    this.editor.customConfig = {
      uploadImgServer: api.FILE_UPLOAD,
      uploadImgMaxLength: 1,
      uploadFileName: 'file',
      uploadImgTimeout: 30000,
      uploadImgParams: { name: 'image.jpg' },
      uploadImgHooks: {
        customInsert: this.handleUploadSuccess,
      },
      onchange: this.onChange,
      ...customConfig,
    };
  }


  render() {
    const { className, style } = this.props;
    return (
      <div className={className} style={style} ref={this.containerRef}></div>
    );
  }
}

RcWangEdite.defaultProps = {
  onChange: noop,
  disabled: false,
  customConfig: {}
};
