/*
 * @Author: xutengfeng 
 * @Date: 2020-02-18 14:38:48 
 * @Last Modified by: xutengfeng
 * @Last Modified time: 2020-02-18 18:22:32
 */
import React, { memo, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './index.less';

/**
 * 
 * @param {String} text 要隐藏的文本
 * @param {String} type 隐藏类型
 * @param {Function} onToggle 控制手机显隐方法
 */

const SecretText = ({ text, type, onToggle }) => {
  const [hide, setHide] = useState(true);
  const [showBtn, result] = useMemo(() => {
    let txt = text;
    const isPhone = /^1[3|4|5|6|7|8|9]\d{9}$/.test(txt);
    if (hide) {
      if (type === 'phone' && isPhone) {
        txt = txt.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
      } else if (type === 'account') {
        txt = txt.replace(/./g, '*');
      } else if(type === 'password') {
        txt = txt.replace(/./g, '*');
      }
    }
    return [['account', 'password'].includes(type) || (type === 'phone' && isPhone), txt];
  }, [hide, text, type]);
  const handleToggle = useCallback(() => {
    setHide(prev => !prev);
    onToggle();
  }, [onToggle]);
  return (
    <span className={styles['phone-container']}>
      {result}
      {
        showBtn && (
          <span className={styles['phone-container-btn']} onClick={handleToggle}>
            {hide ? '显示' : '隐藏'}
          </span>
        )
      }
    </span>
  );
};

SecretText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onToggle: PropTypes.func,
  type: PropTypes.string,
};

SecretText.defaultProps = {
  text: '',
  type: 'phone',
  onToggle: () => {}
};


export default memo(SecretText);