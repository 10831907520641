import {getMenuData} from 'framework/common/menu';
const menuData = getMenuData();
export default {
  namespace: 'menu',
  state: {
    menuKey: Date.now(),
    // 是否显示第三级菜单
    isShowThirdLevelMenu: false,
    // 是否收起1/2级菜单
    isCollapsed: false,
    // 菜单数据
    menuData,
    // 要展开第三级菜单的二级菜单
    activeSubMenu: null,
    // 当前显示的菜单
    currentMenu: null
  },
  effects: {
    *resetMenuData({payload}, {put}){
      const {menuData} = payload;
      yield put({type: 'setMenuData', payload: {menuData}})
    },
    // 切换第1/2级菜单是否收起
    *collapseToggle(_, {select, put}){
      const isCollapsed = yield select(state => state.menu.isCollapsed);
      yield put({type: 'setCollapse', payload: {isCollapsed: !isCollapsed}});
    },
    //设置要展开第三级菜单的二级菜单,并且显示第三级菜单
    *activeSubMenuChange({payload}, {put}){
      const {activeSubMenu} = payload;
      yield put({type: 'setActiveSubMenu', payload: {activeSubMenu}})
    },
    //设置是否显示第三级菜单
    *isShowThirdLevelMenuChange({payload}, {put}){
      const {status} = payload;
      yield put({type: 'setIsShowThirdLevelMenu', payload: {status}})
    },
    //设置当前显示的菜单
    *currentMenuChange({payload}, {put}){
      const {currentMenu} = payload;
      yield put({type: 'setCurrentMenu', payload: {currentMenu}})
    }
  },
  reducers: {
    setMenuData(state, {payload}){
      const {menuData} = payload;
      return {...state, menuData, menuKey: Date.now()};
    },
    setCollapse(state, {payload}){
      const {isCollapsed} = payload;
      return {...state, isCollapsed}
    },
    setActiveSubMenu(state, {payload}){
      const {activeSubMenu} = payload;
      return {...state, activeSubMenu, isShowThirdLevelMenu: !!activeSubMenu};
    },
    setIsShowThirdLevelMenu(state, {payload}){
      const {status} = payload;
      return {...state, isShowThirdLevelMenu: status};
    },
    setCurrentMenu(state, {payload}){
      const {currentMenu} = payload;
      return {...state, currentMenu};
    }
  },
  subscriptions: {},
};
