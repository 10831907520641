import React from "react";
import { Table } from "element-react";
import "element-theme-default";
import classNames from "classnames";
import { Tooltip } from "antd";
import deepClone from "framework/utils/deepClone";
import styles from "./index.less";

const MIN_WIDTH = 80;

export class TooltipCell extends React.Component {
  state = { visible: false };
  handleMouseEnter = () => {
    const tooltipCell = this.refs.tooltipCell;
    const { offsetWidth, scrollWidth } = tooltipCell;
    if (offsetWidth < scrollWidth) {
      this.setState({ visible: true });
    }
  };
  handleMouseLeave = () => {
    this.setState({ visible: false });
  };

  render() {
    const { content } = this.props;
    const { visible } = this.state;
    return (
      <Tooltip placement="topLeft" title={content} visible={visible}>
        <div
          ref="tooltipCell"
          className={styles.tooltipCell}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {content}
        </div>
      </Tooltip>
    );
  }
}

export default class extends React.Component {
  state = { tableColumns: [] };
  renderHeader = (column) => {
    return (
      <div className="normal-header-cell">
        {column.label}
        <i className="header-right-border"></i>
      </div>
    );
  };
  columnsFormat = (columns) => {
    const newColumns = deepClone(columns);
    newColumns.forEach((column, index) => {
      const nextColumn = newColumns[index + 1];
      if (column.sort) {
        column.sortable = "custom";
        delete column.sort;
      }
      if (!column.minWidth || column.minWidth < MIN_WIDTH) {
        column.minWidth = MIN_WIDTH;
      }
      // column.resizable = false;
      if (column.renderHeaderForce) {
        column.renderHeader = column.renderHeaderForce;
      } else if (
        column.fixed ||
        (nextColumn && nextColumn.fixed) ||
        !nextColumn
      ) {
        column.resizable = false;
      } else {
        column.renderHeader = column.renderHeaderForce || this.renderHeader;
      }

      if (column.enums && !column.render) {
        column.render = (row) => {
          const value = row[column.prop];
          const text = column.enums.searchName(value);
          return <TooltipCell content={text} />;
        };
      }
      if (!column.render) {
        column.render = (row) => {
          const text = row[column.prop];
          return <TooltipCell content={text} />;
        };
      }
      if (column.sortable && this.props.onSortChange && !column.sortMethod) {
        column.sortMethod = this.sortFormat;
      }
    });
    return newColumns;
  };
  handleSortChange = ({ column, prop, order }) => {
    const { onSortChange } = this.props;
    let sortType = "";
    if (order === "ascending") {
      sortType = "asc";
    } else if (order === "descending") {
      sortType = "desc";
    }
    this.sortType = sortType;
    onSortChange({ column, sortType });
  };

  componentWillMount() {
    const { columns } = this.props;
    const tableColumns = this.columnsFormat(columns);
    this.setState({ tableColumns });
  }

  sortFormat = () => {
    if ([1, 2].sort((_) => 1).join(",") === "2,1") {
      return this.sortType === "desc";
    }
    return this.sortType === "asc";
  };

  render() {
    const {
      className,
      data,
      onSelectChange,
      seletAllChange,
      rowKey,
      showSummary,
      onSelect,
      height,
      onExpand,
      onRowClick,
      onCurrentChange,
      summaryMethod,
      rowClassName,
      columns,
      customColumns
    } = this.props;
    let { tableColumns } = this.state; 
    // 可以修改 column 
    if(customColumns){
      tableColumns = this.columnsFormat(columns);
    }
    return (
      <div className={classNames(className, styles.table, "cjm-table")}>
        <Table
          summaryMethod={summaryMethod}
          onRowClick={onRowClick}
          onExpand={onExpand}
          showSummary={showSummary}
          ref="table"
          rowKey={rowKey}
          border
          highlightCurrentRow
          onSortChange={this.handleSortChange}
          columns={tableColumns}
          data={data}
          height={height}
          width={{ width: "100%" }}
          onSelectChange={onSelectChange}
          onSelectAll={seletAllChange}
          onSelect={onSelect}
          onCurrentChange={onCurrentChange}
          rowClassName={rowClassName}
        />
      </div>
    );
  }
}
