import React from 'react';
import { Upload, Button } from 'element-react';
import Message from 'src/framework/components/base/Message';
import classNames from 'classnames';
import api from 'framework/common/api';
import uuid from 'src/framework/utils/uuid';
import _ from 'lodash';

import styles from './index.less';
//
//@connect()
class UploadComponent extends React.Component {
  state = { fileName: 'file', uploading: false, key: uuid() };
  beforeUpload = async (file) => {
    this.setState({ fileName: file.name, uploading: true });
  };

  showError = (msg) => {
    msg && Message.error(msg);
  };

  handleSuccess = (res, file, fileList) => {
    const { onSuccess, onError } = this.props;
    if (res && res.state == 200) {
      onSuccess && onSuccess(res, file);
    } else {
      onError ? onError(res) : this.showError(_.get(res, 'msg'));
      this.setState({ key: uuid() });
    }
    this.setState({ uploading: false });
  };
  handleError = () => {
    this.setState({ uploading: false });
  };
  handleRemove = (file, fileList) => {
    const { onRemove, disabled } = this.props;
    !disabled && onRemove && onRemove(file);
  };
  submit = () => {
    this.refs.upload.submit();
  };

  render() {
    const {
      accept, value = [], tip, limit, action, listType,
      disabled = false, autoUpload = true, onExceed, otherParam = {}
    } = this.props;
    const { fileName, uploading, key } = this.state;
    const finalProps = {
      key,
      accept, limit, action, fileList: value, listType, disabled, autoUpload,
      tip: tip ? (<div className="el-upload__tip">{tip}</div>) : '',
      data: { ...{ name: fileName }, ...otherParam },
      onExceed: onExceed,
      onError: this.handleError,
      onSuccess: this.handleSuccess,
      onRemove: this.handleRemove,
      beforeUpload: this.beforeUpload,
      className: classNames(styles.upload, { [styles['disabled']]: disabled })
    };
    if (uploading || value.length === limit) {
      finalProps.disabled = true;
    }
    return (
      <Upload {...finalProps} ref="upload">
        <Button disabled={finalProps.disabled} size="small" type="primary">点击上传</Button>
      </Upload>
    )
  }
}

UploadComponent.defaultProps = {
  action: `${api.FILE_UPLOAD}`,
  limit: 1
};
export default UploadComponent;
